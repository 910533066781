import React, { useState, useEffect } from 'react';
import { getCategories } from '../../../api';  
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../footer";
import { InstructorHeader } from "../../instructor/header";
import InstructorSidebar from "../sidebar";
import { Link } from 'react-router-dom'; 
import { CardImagePlaceHolder } from "../../imagepath";

const ViewCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const data = await getCategories();
        setCategories(data);
      } catch (error) {
        toast.error(error?.error || 'Failed to fetch categories');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <InstructorHeader />
        <div className="page-content">
          <div className="container">
            <div className="row">
              <InstructorSidebar />
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Categories</h3>
                    </div>
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div className="checkout-form settings-wrap">
                        <div className="row">
                          {categories.map((category) => (
                            <div className="col-lg-12 col-md-12 d-flex" key={category.id}>
                              <div className="course-box course-design list-course d-flex">
                                <div className="product">
                                  <div className="product-img">
                                    <Link to={`/category-details/${category.id}`}>
                                      <img
                                        className="img-fluid"
                                        alt={category.title}
                                        src={`${API_URL}/${category.thumbnail}`}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = CardImagePlaceHolder;
                                        }}
                                        style={{
                                          width: "100%",       // Ensures the image takes up the full width of its container
                                          height: "150px",      // Set the fixed height
                                          objectFit: "cover"    // Ensures the aspect ratio is maintained and the image is cropped as needed
                                        }}
                                      />
                                    </Link>
                                  </div>
                                  <div className="product-content">
                                    <div className="head-course-title">
                                      <h3 className="title">
                                        <Link to={`/category-details/${category.id}`}>
                                          {category.title}
                                        </Link>
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewCategories;
