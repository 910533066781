// src/utils/auth.js

export const getAccessToken = () => {
    return localStorage.getItem('access_token');
  };
  
  export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };
  
  export const setTokens = (accessToken, refreshToken) => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  };

  // Set only the access token (for token refresh)
export const setAccessToken = (accessToken) => {
  localStorage.setItem('access_token', accessToken);
};
  
 // Clear tokens on logout
export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

// Logout function to clear tokens and redirect
export const logoutUser = () => {
  clearTokens();
  window.location.href = '/login'; // Redirect to login
};
  