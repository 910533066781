import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import PageHeader from "../header";
import { sendSupportRequest } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Support = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    subject: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendSupportRequest(
        formData.first_name,
        formData.email,
        formData.subject,
        formData.description
      );
      toast.success(response.message);
      setFormData({ first_name: "", email: "", subject: "", description: "" });
    } catch (error) {
      const errorMessage = error.error || "Failed to send support request";
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <PageHeader activeMenu="Support" />
        <ToastContainer />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Pages</li>
                      <li className="breadcrumb-item">Support</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner quick-links">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Support</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 mx-auto">
                <div className="support-wrap">
                  <h5>Submit a Request</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        placeholder="Enter your first name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-block">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-block">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Enter your subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-block">
                      <label>Description</label>
                      <textarea
                        name="description"
                        className="form-control"
                        placeholder="Write down here"
                        rows={4}
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <button type="submit" className="btn-submit">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Support;
