import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { GoogleLogin } from "@react-oauth/google"; // Import Google login component
import { LoginImg, logoPng, logoBlack } from "../../imagepath";
import { register, fetchUserCategories } from "../../../api"; 
import { AuthContext } from "../../../context/AuthContext"; // Import AuthContext

const Register = () => {
  const [eye, setEye] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [userCategories, setUserCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();
  const { handleGoogleRegister } = useContext(AuthContext); // Use Google Register from AuthContext

  useEffect(() => {
    const loadUserCategories = async () => {
      try {
        const categories = await fetchUserCategories();
        setUserCategories(categories);
      } catch (error) {
        setError("Failed to load user categories.");
      }
    };
    loadUserCategories();
  }, []);

  useEffect(() => {
    if (password && confirmPassword) {
      setPasswordMatchError(password !== confirmPassword ? "Passwords do not match." : "");
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      await register(firstName, lastName, email, password, selectedCategory);
      navigate("/login");
    } catch (err) {
      setError(err.error || "An unknown error occurred.");
    }
  };

  const handleGoogleRegisterSuccess = async (credentialResponse) => {
    try {
      await handleGoogleRegister(credentialResponse.credential);
      navigate("/student/student-dashboard");
    } catch (error) {
      setError("Google registration failed.");
    }
  };

  return (
    <div className="main-wrapper log-wrap">
      <div className="row">
        <div className="col-md-6 login-bg">
          <div className="back-home" style={{ position: "absolute", top: "10px", left: "10px", zIndex: 10 }}>
            <Link to="/home">Back to Home</Link>
          </div>
          <OwlCarousel items={1} margin={25} dots={true} nav={true} loop={true}>
            <div className="welcome-login">
              <div className="login-banner">
                <img src={LoginImg} className="img-fluid" alt="Logo" />
              </div>
              <div className="img-logo mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <img src={logoBlack} className="img-fluid" alt="Logo" style={{ width: "150px" }} />
              </div>
              <div className="mentor-course text-center">
                <h2>WELCOME TO J’FUNZE</h2>
                <p>Fostering individual and peer-to-peer learning, and organizational knowledge sharing.</p>
              </div>
            </div>
          </OwlCarousel>
        </div>
        <div className="col-md-6 login-wrap-bg">
          <div className="login-wrapper">
            <div className="loginbox">
              <div className="img-logo" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={logoPng} className="img-fluid" alt="Logo" />
              </div>
              <h1>Sign up</h1>
              <form onSubmit={handleRegister}>
                <div className="input-block">
                  <label className="form-control-label">First Name</label>
                  <input type="text" className="form-control" placeholder="Enter your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Last Name</label>
                  <input type="text" className="form-control" placeholder="Enter your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Email</label>
                  <input type="email" className="form-control" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Department</label>
                  <select className="form-control" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                    <option value="">Select A Department</option>
                    {userCategories.map((category) => (
                      <option key={category.id} value={category.id}>{category.title}</option>
                    ))}
                  </select>
                </div>
                <div className="input-block">
                  <label className="form-control-label">Password</label>
                  <div className="pass-group">
                    <input className="form-control pass-input" placeholder="Enter your password" type={eye ? "password" : "text"} value={password} onChange={handlePasswordChange} required />
                  </div>
                </div>
                <div className="input-block">
                  <label className="form-control-label">Confirm Password</label>
                  <div className="pass-group">
                    <input className="form-control" placeholder="Confirm your password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />
                  </div>
                </div>
                {passwordMatchError && <div style={{ color: "red", fontSize: "14px" }}>{passwordMatchError}</div>}
                {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-start" disabled={passwordMatchError !== ""}>Create Account</button>
                </div>
              </form>
              {/* <div className="sign-google" style={{ textAlign: "center", fontWeight: "bold" }}>
                <GoogleLogin onSuccess={handleGoogleRegisterSuccess} onError={() => setError("Google register error")} />
              </div> */}
              <div className="google-bg text-center">
                <p className="mb-0">Already have an account? <Link to="/login">Sign in</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
