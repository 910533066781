import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import AdminSideBar from "../../../admin/sidebar";
import InstructorSideBar from "../../../instructor/sidebar";
import { InstructorHeader } from "../../../instructor/header";
import { getCourseEnrollments } from "../../../../api";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext.js";
import "bootstrap/dist/css/bootstrap.min.css";

const CourseEnrollments = () => {
  const { courseId } = useParams();
  const { userInfo } = useContext(AuthContext);
  const [courseDetails, setCourseDetails] = useState(null);
  const [enrollments, setEnrollments] = useState([]);

  // Fetch enrollments
  const fetchEnrollments = async () => {
    try {
      const response = await getCourseEnrollments(courseId);
      console.log("API Response:", response.data); // Debugging the response
      const course = response.data.results.course;
      const enrollmentData = response.data.results.enrollments;
      setCourseDetails(course || {});
      setEnrollments(enrollmentData || []);
    } catch (error) {
      console.error("Error fetching enrollments:", error);
      toast.error("Failed to fetch enrollments");
    }
  };

  useEffect(() => {
    fetchEnrollments();
  }, [courseId]);

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Course"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            {userInfo?.role === "superadmin" ? (
              <AdminSideBar />
            ) : (
              <InstructorSideBar />
            )}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{courseDetails?.title || "Loading Course..."}</h3>
                    <p>Enrolled Learners</p>
                  </div>
                  <div className="checkout-form pb-0">
                    {enrollments.length > 0 ? (
                      <table className="table">
                        <thead style={{ backgroundColor: "#f0f0f0" }}>
                          <tr>
                            <th>Learner</th>
                            <th>Email</th>
                            <th>Progress (%)</th>
                            <th>Start Date</th>
                            <th>Last Accessed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enrollments.map((enrollment) => (
                            <tr key={enrollment.id}>
                              <td>
                                {enrollment.user.first_name}{" "}
                                {enrollment.user.last_name}
                              </td>
                              <td>{enrollment.user.email}</td>
                              <td>{enrollment.progress}%</td>
                              <td>
                                {new Date(
                                  enrollment.created_at
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                {enrollment.last_accessed_lesson
                                  ? new Date(
                                      enrollment.last_accessed_lesson
                                        .last_viewed_at
                                    ).toLocaleDateString()
                                  : "Never Accessed"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No learners enrolled yet.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CourseEnrollments;
