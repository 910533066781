import React, { useState, useEffect } from "react";
import Footer from "../../footer";
import { InstructorHeader } from "../../instructor/header";
import {CardImagePlaceHolder } from "../../imagepath";
import InstructorSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { getCourses } from "../../../api";

export const Dashboard = () => {
  const [isClassAdded, setIsClassAdded] = useState([false]);
  const [recentCourses, setRecentCourses] = useState([]);
  const API_URL = process.env.REACT_APP_MEDIA_URL

  useEffect(() => {
      const fetchCourses = async () => {
          try {
              const response = await getCourses();
              const courses = response.data.results // Get the courses from the response
              // Sort courses by created_at date and get the last 5
              
              const sortedCourses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              setRecentCourses(sortedCourses.slice(0, 5));
          } catch (error) {
              console.error("Error fetching courses:", error);
          }
      };

      fetchCourses();
  }, []);

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };
  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Dashboard"} />
      
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* sidebar */}
            <InstructorSidebar />
            {/* /Sidebar */}
            {/* Student Dashboard */}
            <div className="col-xl-9 col-lg-9">
              {/* Dashboard Grid */}
              <div className="row justify-content-center">
                {/* <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Enrolled Courses</h5>
                      <h2>13</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Active Courses</h5>
                      <h2>08</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Completed Courses</h5>
                      <h2>06</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Total Students</h5>
                      <h2>5</h2>
                    </div>
                  </div>
                </div> */}
                
               
              </div>
              {/* /Dashboard Grid */}
              <div className="instructor-course-table">
                <div className="dashboard-title">
                  <h4>Recently Created Courses</h4>
                </div>
                <div className="table-responsive custom-table">
                  {/* Referred Users*/}
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>Courses</th>
                        <th>Enrolled</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {recentCourses.map((course, index) => (
                                              <tr key={course.id}>
                                                  <td>
                                                      <div className="table-course-detail">
                                                          <Link to={`/course-details/${course.id}`} className="course-table-img">
                                                              <span>
                                                                  <img
                                                                      src={`${API_URL}/${course.icon}`} // Assuming you want to use the course icon
                                                                      alt="Img"
                                                                      style={{
                                                                        width: "80px",
                                                                        height: "80px",
                                                                        objectFit: "cover",
                                                                      }}
                                                                      onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = CardImagePlaceHolder;
                                                                      }}
                                                                  />

                                                              </span>
                                                              {course.title}
                                                          </Link>
                                                      </div>
                                                  </td>
                                                  <td>0</td>
                                                  <td>Published</td>
                                              </tr>
                                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className="dash-pagination">
                <div className="row align-items-center">
                  <div className="col-6">
                    <p>Page 1 of 2</p>
                  </div>
                  <div className="col-6">
                    <ul className="pagination">
                      <li className="active">
                        <Link to="#">1</Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="bx bx-chevron-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Student Dashboard */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </div>

  );
};
