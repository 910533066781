import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CourseHeader from "../header";
import DetailsContent from "./detailsContent";
import { Icon1, Timer } from "../../../imagepath";
import Footer from "../../../footer";
import { Link } from "react-router-dom";
import { getCourseDetails } from "../../../../api"; 

const CourseDetails = () => {
  // Get the course ID from the URL
  const { courseId } = useParams(); 
  const [course, setCourse] = useState(null);
  // State to store the total number of lessons
  const [totalLessons, setTotalLessons] = useState(0); 

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await getCourseDetails(courseId);
        // Set the course state with the fetched details
        setCourse(response); 
        

        // Calculate the total number of lessons
        const lessonsCount = response.sections.reduce((total, section) => {
          return total + section.lessons.length;
        }, 0);
        // Update the total number of lessons
        setTotalLessons(lessonsCount); 
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchCourseDetails();
    
  }, [courseId]);

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader activeMenu={"CourseDetails"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {course ? course.title : "Loading..."} 
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="inner-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0"></div>
                <h2>{course ? course.title : "Loading..."}</h2> 
                <p>{course ? course.outcomes : "Loading course outcomes..."}</p> 
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                  <div className="cou-info">
                    <i className="fa fa-book course-icon" aria-hidden="true"></i>
                    <p>{totalLessons} Lessons</p> 
                  </div>
                  <div className="cou-info">
                    <img src={Timer} alt="" />
                    <p>{course ? course.duration : "Loading..."} hours</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DetailsContent />

        <Footer />
      </div>
    </>
  );
};

export default CourseDetails;
