import React, { useEffect, useState } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import Footer from "../../footer";
import { listCertificates } from "../../../api";
import { format } from "date-fns";

const StudentCertificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const token = localStorage.getItem("access_token"); // Retrieve token from storage
        const data = await listCertificates(token);
        setCertificates(data);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificates();
  }, []);

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"My Quiz Attempts"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <StudentSidebar />
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>My Certificates</h3>
                  </div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : certificates.length > 0 ? (
                    <div className="checkout-form">
                      <div className="table-responsive custom-table">
                        <table className="table table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th>Student Name</th>
                              <th>Course Title</th>
                              <th>Issued At</th>
                              <th>Certificate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {certificates.map((certificate) => (
                              <tr key={certificate.id}>
                                <td>{certificate.user_name}</td>
                                <td>{certificate.course_title}</td>
                                <td>{format(new Date(certificate.issued_at), "dd MMM yyyy")}</td>
                                <td>
                                  {certificate.is_active && (
                                    <a
                                      href={certificate.certificate_file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Download
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <p>No certificates available for the user.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentCertificates;
