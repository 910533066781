import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { User17 } from "../../imagepath";
import { AuthContext } from "../../../context/AuthContext.js";

// eslint-disable-next-line react/prop-types
export default function AdminSidebar() {
  const { userInfo } = useContext(AuthContext); 
  const location = useLocation();
  const navigate = useNavigate();
  const {logoutUser } = useContext(AuthContext);

  const handleLogoout = (e) => {
    e.preventDefault();
    logoutUser();
    navigate ('/login');
  }

  return (
    <div className="col-xl-3 col-lg-3 theiaStickySidebar">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="settings-widget dash-profile">
          <div className="settings-menu">
            <div className="profile-bg">
              <div className="profile-img" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
                <Link to="/instructor/instructor-profile">
                {/* <img
                src={userInfo?.profile_picture || User17} 
                alt="User"
              /> */}

              
<div
        style={{
          backgroundColor: '#D9D9C3',
          color: '#000',
          border: '5px solid #fff',
          borderRadius: '50%',
          width: '125px',
          height: '125px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '36px', // Adjust this to fit the size of the circle
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {userInfo?.first_name?.[0]}{userInfo?.last_name?.[0]}
      </div>
                </Link>
              </div>
            </div>
            <div className="profile-group">
              <div className="profile-name text-center">
                <h4>
                <Link to="/student/student-profile">{userInfo?.first_name} {userInfo?.last_name}</Link>
                </h4>
                <p>Admin</p>
                <Link to="/add-course" className="add-course btn-primary">
                  Add New Course
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-widget account-settings">
          <div className="settings-menu">
            <h3>Admin Dashboard</h3>
            <ul>
              <li className={`nav-item ${location.pathname === '/admin/admin-dashboard' ? 'active' : ''}`}>

                <Link to="/admin/admin-dashboard" className="nav-link">
                  <i className="bx bxs-tachometer" />
                  Dashboard
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/instructor/instructor-profiles' ? 'active' : ''}`}>

                <Link to="/admin/admin-profile" className="nav-link">
                  <i className="bx bxs-user" />
                  My Profile
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/instructor/instructor-course' ? 'active' : ''}`}>
                <Link to="/admin/admin-course" className="nav-link ">
                  <i className="bx bxs-rocket" />
                  Courses
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/manage/assessment-submissions' ? 'active' : ''}`}>
                <Link to="/manage/assessment-submissions" className="nav-link">
                  <i className="bx bxs-shapes" />
                Assessments Submission
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === 'manage/categories' ? 'active' : ''}`}>

                <Link to="/admin/categories" className="nav-link">
                  <i className="bx bxs-volume-full" />
                  Categories
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/admin/manage-users' ? 'active' : ''}`}>

                <Link to="/admin/manage-users" className="nav-link">
                  <i className="bx bxs-volume-full" />
                  Users
                </Link>
              </li>
            </ul>
            {/* <h3>admin</h3> */}
           
            <h3>Account Settings</h3>
            <ul>
            <li className={`nav-item ${location.pathname === '/instructor/instructor-settings' || location.pathname === '/instructor/instructor-change-password' || location.pathname === '/instructor/instructor-setting-notifications' || location.pathname === '/instructor/instructor-setting-withdraw' || location.pathname === '/instructor/instructor-delete-account' ? 'active' : ''}`}>

                <Link to="/admin/admin-settings" className="nav-link ">
                  <i className="bx bxs-cog" />
                  Settings
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/logout" onClick={handleLogoout} className="nav-link">
                  <i className="bx bxs-log-out" />
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
