import React, { useState, useEffect, useContext } from "react";
import StudentSidebar from "../../student/sidebar";
import StudentHeader from "../../student/header";
import { Link } from "react-router-dom";
import { CardImagePlaceHolder } from "../../imagepath";

import { LifeAtSolidaridadVideo } from "../../videopath";
import Footer from "../../footer";
import { getUserEnrollments } from "../../../api";
import { AuthContext } from "../../../context/AuthContext";

const LifeAtSolidAridad = () => {
  const [isClassAdded, setIsClassAdded] = useState([false]);
  const { userInfo } = useContext(AuthContext);
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };

  // Filter active courses
  const activeCourses = enrolledCourses.filter(
    (enrollment) => enrollment.course.is_course_active
  );

  return (
    <>
    {/* Main Wrapper */}
    <div className="main-wrapper">
      {/* Header */}
      <StudentHeader activeMenu={"Life at Solidaridad"} />
     {/* /Header */}
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* sidebar */}
            <StudentSidebar />
            {/* /Sidebar */}
            {/* Student Courses */}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Life At Solidaridad</h3>
                  </div>
                  <div className="checkout-form pb-0">
                    <div className="wishlist-tab"></div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="enroll-courses"
                      >
                        <div className="row">
                          <div className="col-12">
                            <video
                              controls
                              src={LifeAtSolidaridadVideo}
                              style={{ width: "100%", height: "auto" }}
                            >
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Student Courses */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </div>
     {/* /Main Wrapper */}
    </>
  );
};

export default LifeAtSolidAridad;
