import React, { useEffect, useState } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import Footer from "../../footer";
import { Link } from "react-router-dom";
import { getUserSubmissions, getCourseDetails, getLessonDetails } from "../../../api";
import { format } from "date-fns";

const StudentAssessment = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user submissions
        const data = await getUserSubmissions({});
        
        // Process each submission to get course and lesson names
        const enrichedSubmissions = await Promise.all(
          data.results.map(async (submission) => {
            try {
              const courseName = (await getCourseDetails(submission.assessment.course)).title;
              // const lessonName = (await getLessonDetails(submission.assessment.course, submission.assessment.lesson, submission.assessment.lesson)).title;
              
              return {
                ...submission,
                courseName,
                // lessonName,
              };
            } catch (error) {
              console.error("Error fetching course or lesson details:", error);
              return { ...submission, courseName: "N/A", lessonName: "N/A" };
            }
          })
        );
        
        setSubmissions(enrichedSubmissions);
      } catch (error) {
        console.error("Error fetching submissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"My Quiz Attempts"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <StudentSidebar />
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>My Assessments</h3>
                  </div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="checkout-form">
                      <div className="table-responsive custom-table">
                        <table className="table table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th>Assessment Title</th>
                              <th>Date Submitted</th>
                              <th>Total Marks</th>
                              <th>Earned Marks</th>
                              <th>Result</th>
                              {/* <th>Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {submissions.map((submission) => (
                              <tr key={submission.id}>
                                <td>
                                  <div className="quiz-table">
                                    <p>{submission.assessment.title}</p>
                                    <p className="text-muted small">
                                      Course: {submission.courseName} <br />
                                      {/* Lesson: {submission.lessonName} */}
                                    </p>
                                  </div>
                                </td>
                                <td>{format(new Date(submission.submitted_at), "MMMM dd, yyyy hh:mm a")}</td>
                                <td>{submission.assessment.questions.length * 2}</td> {/* Assuming each question is worth 2 marks */}
                                <td>{submission.grade}</td>
                                <td>
                                  <span
                                    className={`resut-badge ${
                                      submission.is_passed ? "badge-light-success" : "badge-light-danger"
                                    }`}
                                  >
                                    {submission.is_passed ? "Pass" : "Fail"}
                                  </span>
                                </td>
                                {/* <td>
                                  <Link
                                    to={`/student/student-assessment-details/${submission.id}`}
                                    className="btn btn-light-danger quiz-view"
                                  >
                                    Details
                                  </Link>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentAssessment;
