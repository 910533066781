import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { GoogleLogin } from "@react-oauth/google"; // Import Google login component
import {
  LoginImg,
  logoBlack,
  logoPng,
  NetIcon1,
} from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { login, getUserEnrollments } from "../../../api";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { loginUser, handleGoogleLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const togglePassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await login(email, password);
      loginUser(response);

      if (response.user.role === "learner") {
        const enrollmentsResponse = await getUserEnrollments(response.user.id);
        const enrollments = enrollmentsResponse.data.results;

        if (enrollments.length > 0) {
          navigate("/student/student-courses");
        } else {
          navigate("/student/student-dashboard");
        }
      } else if (response.user.role === "instructor") {
        navigate("/instructor/instructor-dashboard");
      } else {
        navigate("/admin/admin-dashboard");
      }
    } catch (error) {
      const errorMessage = error.error;
      setError(errorMessage);
      console.error("Login failed:", error);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      await handleGoogleLogin(credentialResponse.credential);
      navigate("/student/student-dashboard"); // Navigate as needed on successful login
    } catch (error) {
      setError("Google login failed.");
    }
  };

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          <div className="col-md-6 login-bg">
            <div
              className="back-home"
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                zIndex: 10,
              }}
            >
              <Link to="/home">Back to Home</Link>
            </div>

            <OwlCarousel items={1} margin={25} dots={true} nav={true} loop={true}>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="img-logo mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <img src={logoBlack} className="img-fluid" alt="Logo" style={{ width: "150px" }} />
                </div>
                <div className="mentor-course text-center">
                  <h2>WELCOME TO J’FUNZE</h2>
                  <p>Fostering individual and peer-to-peer learning, and organizational knowledge sharing.</p>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div className="col-md-6 login-wrap-bg">
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <img src={logoPng} className="img-fluid" alt="Logo" />
                  </div>

                  <h1>Sign In</h1>
                  <form onSubmit={handleLogin}>
                    <div className="input-block">
                      <label className="form-control-label">Email</label>
                      <input type="email" className="form-control" placeholder="Enter your email address" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="input-block">
                      <label className="form-control-label">Password</label>
                      <div className="pass-group">
                        <input type={passwordType} className="form-control" placeholder="Password" value={password} onChange={handlePasswordChange} />
                        <span className="toggle-password feather-eye" onClick={togglePassword}>
                          {passwordType === "password" ? <FeatherIcon icon="eye" /> : <FeatherIcon icon="eye-off" />}
                        </span>
                      </div>
                    </div>
                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">Forgot Password ?</Link>
                      </span>
                    </div>
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    <div className="d-grid">
                      <button className="btn btn-start" type="submit">Sign In</button>
                    </div>
                    <div className="sign-google" style={{ textAlign: "center", fontWeight: "bold" }}>
                      <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={() => setError("Google login error")} />
                    </div>
                    <div className="google-bg text-center">
                      <p className="mb-0">New User ? <Link to="/register">Create an Account</Link></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
