import React, { useState, useEffect, useContext } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getCourses, getUserEnrollments } from '../../../api';
import { AuthContext } from '../../../context/AuthContext'; // Import AuthContext
import {
 CardImagePlaceHolder
} from "../../imagepath";
import Footer from "../../footer";

const StudentDashboard = () => {
  const { userInfo } = useContext(AuthContext); // Get userInfo from AuthContext
  const [isClassAdded, setIsClassAdded] = useState([false]);

  const [courses, setCourses] = useState([]); // For available courses
  const [enrollments, setEnrollments] = useState([]); // For user enrollments
  const [activeCourses, setActiveCourses] = useState(0);
  const [completedCourses, setCompletedCourses] = useState(0);
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // Fetch available courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(response.data.results);
      } catch (error) {
        toast.error('Failed to fetch courses');
      }
    };

    fetchCourses();
  }, []);

  // Fetch user enrollments
  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        if (userInfo) {
         
          const response = await getUserEnrollments(userInfo.id);
          const enrollmentsData = response.data.results;

          setEnrollments(enrollmentsData);
          setTotalEnrollments(enrollmentsData.length);

          // Count active and completed courses
          const active = enrollmentsData.filter((enrollment) => enrollment.course.is_course_active).length;
          const completed = enrollmentsData.filter((enrollment) => enrollment.progress === 100.0).length;

          setActiveCourses(active);
          setCompletedCourses(completed);
        }
      } catch (error) {
        toast.error('Failed to fetch enrollments');
      }
    };

    fetchEnrollments();
  }, [userInfo]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper">
        {/* Header */}
        <StudentHeader activeMenu={"Dashboard"} />
        {/* /Header */}
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Student Dashboard */}
              <div className="col-xl-9 col-lg-9">
                {/* Dashboard Grid */}
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 d-flex">
                    <div className="card dash-info flex-fill">
                      <div className="card-body">
                        <h5>Enrolled Courses</h5>
                        <h2>{totalEnrollments}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex">
                    <div className="card dash-info flex-fill">
                      <div className="card-body">
                        <h5>Active Courses</h5>
                        <h2>{activeCourses}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 d-flex">
                    <div className="card dash-info flex-fill">
                      <div className="card-body">
                        <h5>Completed Courses</h5>
                        <h2>{completedCourses}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Dashboard Grid */}
                {/* Dashboard Title */}
                <div className="dashboard-title">
                  <h4>Courses</h4>
                </div>
                <div className="row">
                  {/* Course Grid */}
                  {courses.length > 0 ? (
                    courses.map((course) => (
                      <div className="col-xxl-4 col-md-6 d-flex" key={course.id}>
                        <div className="course-box flex-fill">
                          <div className="product">
                            <div className="product-img">
                              <Link to={`/course-details/${course.id}`}>
                                <img
                                  className="img-fluid"
                                  alt="Course"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                  src={`${API_URL}/${course.banner}`}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = CardImagePlaceHolder;
                                  }}
                                />
                              </Link>
                            </div>
                            <div className="product-content">
                              <h3 className="title instructor-text">
                                <Link to={`/course-details/${course.id}`}>
                                  {course.title}
                                </Link>
                              </h3>
                              <h6>{truncateText(course.description, 100)}</h6>
                              <div className="course-info d-flex align-items-center">
                                <div className="rating-img d-flex align-items-center">
                                  <i
                                    className="fa fa-book course-card-icon"
                                    aria-hidden="true"
                                  ></i>
                                  <p>10+ </p>
                                </div>
                                <div className="course-view d-flex align-items-center">
                                  <i
                                    className="fa fa-clock course-card-icon"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{course.duration} hours</p>
                                </div>
                              </div>
                              {/* <div className="rating mb-0">
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <span className="d-inline-block average-rating">
                                  <span>5.0</span> (20)
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No courses found.</p>
                  )}
                  {/* /Course Grid */}
                </div>
              </div>
              {/* Student Dashboard */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <Footer />
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default StudentDashboard;
