import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createCourse, getCategories } from "../../../../api";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import Footer from "../../../footer";
import CourseHeader from "../header";
import InstructorSidebar from "../../../instructor/sidebar";
import AdminSidebar from "../../../admin/sidebar";  // Import AdminSidebar
import { AuthContext } from "../../../../context/AuthContext"; // Import AuthContext

const AddCourse = () => {
  const { userInfo } = useContext(AuthContext);  // Get userInfo from AuthContext
  const [course, setCourse] = useState({
    title: '',
    description: '',
    objectives: '',
    duration: '',
    outcomes: '',
    categories: [],
    banner: null,
    icon: null
  });
  
  const [categories, setCategories] = useState([]);
  const [banner, setBanner] = useState(null);
  const [icon, setIcon] = useState(null);
  const navigate = useNavigate();

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        toast.error("Failed to fetch categories");
      }
    };
    fetchCategories();
  }, []);

  // Handle form changes
  const handleChange = (field, value) => {
    setCourse({ ...course, [field]: value });
  };

  // Handle category change for multi-select
  const handleCategoryChange = (selectedOptions) => {
    const selectedCategories = selectedOptions.map(option => option.value);
    setCourse({ ...course, categories: selectedCategories });
  };

  // Handle file uploads
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "banner") setBanner(files[0]);
    if (name === "icon") setIcon(files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("title", course.title);
    formData.append("description", course.description);
    formData.append("objectives", course.objectives);
    formData.append("duration", course.duration);
    formData.append("outcomes", course.outcomes);
    formData.append("categories", course.categories.join(','));
    if (banner) formData.append("banner", banner);
    if (icon) formData.append("icon", icon);
    formData.append("is_course_active", true);
    formData.append("is_featured", true);
  
    try {
      const response = await createCourse(formData);
      const courseId = response.id;
  
      toast.success("Course created successfully!");
  
      setTimeout(() => {
        navigate(`/admin/course/${courseId}/manage-curriculum`);
      }, 1000); 
    } catch (error) {
      console.error("Error creating course:", error);
      toast.error("Failed to create course");
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader />
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Conditionally render either InstructorSidebar or AdminSidebar */}
              {userInfo?.role === 'superadmin' ? <AdminSidebar /> : <InstructorSidebar />}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Add A Course</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="checkout-form settings-wrap">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="add-course-label">Course Title</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Course Title"
                                value={course.title}
                                onChange={(e) => handleChange('title', e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Courses Category</label>
                              <Select
                                isMulti
                                options={categories.map(category => ({ label: category.title, value: category.id }))}
                                onChange={handleCategoryChange}
                                placeholder="Select Categories"
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Description</label>
                              <textarea
                                className="form-control"
                                placeholder="Course description"
                                value={course.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Objectives</label>
                              <textarea
                                className="form-control"
                                placeholder="Course Objectives"
                                value={course.objectives}
                                onChange={(e) => handleChange('objectives', e.target.value)}
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Duration (Hours)</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Duration"
                                value={course.duration}
                                onChange={(e) => handleChange('duration', e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Outcomes</label>
                              <textarea
                                className="form-control"
                                placeholder="Course Outcomes"
                                value={course.outcomes}
                                onChange={(e) => handleChange('outcomes', e.target.value)}
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Cover Image</label>
                              <div className="relative-form">
                                <span>{banner ? banner.name : "No File Selected"}</span>
                                <label className="relative-file-upload">
                                  Upload File
                                  <input
                                    type="file"
                                    name="banner"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Course Icon</label>
                              <div className="relative-form">
                                <span>{icon ? icon.name : "No File Selected"}</span>
                                <label className="relative-file-upload">
                                  Upload File
                                  <input
                                    type="file"
                                    name="icon"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                  />
                                </label>
                              </div>
                            </div>
                            <button className="btn btn-primary btn-add-course" type="submit">
                              Create Course
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};

export default AddCourse;
