import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { User16 } from "../../imagepath";
import StudentSettingPageHeader from "./settingPageHeader";
import { getUserProfile, updateUserProfile } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import Footer from "../../footer";
import { AuthContext } from "../../../context/AuthContext";


const StudentSetting = () => {
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    profile_picture: null,
  });

  const { setUserInfo } = useContext(AuthContext)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        const { first_name, last_name, email, role, profile_picture } = response.data;
        setProfile({ first_name, last_name, email, role, profile_picture });
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleFileChange = (e) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      profile_picture: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = await updateUserProfile(profile);
     
      toast.success("Profile updated successfully!");
      
      setUserInfo(updatedProfile);
      setTimeout(() => {
        navigate("/student/student-profile"); 
      }, 2000); 
    } catch (error) {
      console.error("Error updating profile", error);
      toast.error("Failed to update profile");
    }
  };

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Settings"} />
      <ToastContainer /> {/* ToastContainer for notifications */}
     

      <div className="page-content">
        <div className="container">
          <div className="row">
            <StudentSidebar />
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Settings </h3>
                    <p>Edit your profile details below.</p>
                  </div>
                  <StudentSettingPageHeader />
                  <form onSubmit={handleSubmit}>
                    <div className="course-group profile-upload-group mb-0 d-flex">
                      <div className="course-group-img profile-edit-field d-flex align-items-center">
                        <Link to="/student/student-profile" className="profile-pic">
                          <img
                            src={profile.profile_picture || User16}
                            alt="Profile"
                            className="img-fluid"
                          />
                        </Link>
                        <div className="profile-upload-head">
                          <h4>Your Avatar</h4>
                          <p>PNG or JPG no bigger than 800px width and height</p>
                          <div className="new-employee-field">
                            <div className="image-upload mb-0">
                              <input type="file" onChange={handleFileChange} />
                              <div className="image-uploads">
                                <i className="bx bx-cloud-upload" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="checkout-form settings-wrap">
                      <div className="edit-profile-info">
                        <h5>Personal Details</h5>
                        <p>Edit your personal information</p>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block">
                            <label className="form-label">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              value={profile.first_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              value={profile.last_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label className="form-label">Role</label>
                            <input
                              type="text"
                              className="form-control"
                              value={profile.role}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={profile.email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <button className="btn btn-primary" type="submit">
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentSetting;
