import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { listSectionsForCourse, createSection, createLesson } from "../../../../../api";
import { toast, ToastContainer } from "react-toastify"; 
import Footer from "../../../../footer";
import CourseHeader from "../../header";
import InstructorSidebar from "../../../../instructor/sidebar";
import AdminSidebar from "../../../../admin/sidebar";  // Import AdminSidebar
import { AuthContext } from "../../../../../context/AuthContext";

const EditCurriculum = () => {
  const { userInfo } = useContext(AuthContext); 
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showLessonModal, setShowLessonModal] = useState(false);
  const [newSection, setNewSection] = useState({ title: "", description: "",});
  const [newLesson, setNewLesson] = useState({ title: "", content_type: "", content_file: null, content: "" });
  const [selectedSection, setSelectedSection] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await listSectionsForCourse(courseId, {}, token);
      setSections(response.results);
    } catch (error) {
      toast.error("Failed to load sections.");
    }
  };

  const handleAddSection = async () => {
    try {
      await createSection(courseId, newSection, token);
      toast.success("Section added successfully!");
      fetchSections();
      setShowSectionModal(false);
    } catch (error) {
      toast.error("Failed to add section.");
    }
  };

  const handleAddLesson = async () => {
    try {
      await createLesson(courseId, selectedSection, newLesson, token);
      toast.success("Lesson added successfully!");
      fetchSections(); 
      setShowLessonModal(false);
    } catch (error) {
      toast.error("Failed to add lesson.");
    }
  };

  const handleViewCourse = () => {
    navigate(`/course-details/${courseId}`); 
  };

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader />
        <div className="page-content">
          <div className="container">
            <div className="row">
             {/* Conditionally render either InstructorSidebar or AdminSidebar */}
             {userInfo?.role === 'superadmin' ? <AdminSidebar /> : <InstructorSidebar />}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Course Curriculum</h3>
                    </div>
                    
                    <fieldset className="field-card" style={{ display: "block" }}>
                      <div className="add-course-info">
                        <div className="add-course-inner-header">
                          <h4>Curriculum</h4>
                        </div>
                        <div className="add-course-section">
                          <button className="btn" onClick={() => setShowSectionModal(true)}>
                            Add Section
                          </button>
                        </div>
                        
                        {sections.length > 0 ? (
                          sections.map(section => (
                            <div key={section.id} className="curriculum-grid">
                              <div className="curriculum-head">
                                <p>{`Section ${section.order}: ${section.title}`}</p>
                                <button className="btn" onClick={() => { setSelectedSection(section.id); setShowLessonModal(true); }}>
                                  Add Lesson
                                </button>
                              </div>
                              <div className="curriculum-info">
                                {section.lessons.length > 0 ? (
                                  section.lessons.map(lesson => (
                                    <div key={lesson.id} className="faq-grid">
                                      <p>{lesson.title}</p>
                                    </div>
                                  ))
                                ) : (
                                  <p>No lessons added yet.</p>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No sections available yet.</p>
                        )}
                      </div>
                      <div className="widget-btn">
            {/* <Link className="btn btn-black prev_btn" >Previous</Link> */}
            {/* <Link className="btn btn-info-light next_btn" onClick={handleViewCourse}>ViewCourse</Link>
             */}
             <button className="btn btn-info-light next_btn" onClick={handleViewCourse}>
  View Course
</button>
          </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />

        {/* Add Section Modal */}
        {showSectionModal && (
  <div className="modal show fade" style={{ display: 'block' }} tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Add Section</h5>
          <button
            type="button"
            className="btn-close btn-modal-close"
            onClick={() => setShowSectionModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <label className="form-label">Section Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Section Title"
              value={newSection.title}
              onChange={(e) => setNewSection({ ...newSection, title: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Section Description</label>
            <input
              type="text"
              className="form-control"
              placeholder="Section Description"
              value={newSection.description}
              onChange={(e) => setNewSection({ ...newSection, description: e.target.value })}
            />
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="Order"
              value={newSection.order}
              onChange={(e) => setNewSection({ ...newSection, order: e.target.value })}
            />
          </div> */}
        </div>
        <div className="modal-footer">
          <button className="btn-modal-close btn btn-secondary" onClick={() => setShowSectionModal(false)}>
            Close
          </button>
          <button className="btn-modal-add btn btn-primary" onClick={handleAddSection}>
            Add Section
          </button>
        </div>
      </div>
    </div>
  </div>
)}

        {/* Add Lesson Modal */}
        {showLessonModal && (
  <div className="modal show fade" style={{ display: 'block' }} tabIndex="-1">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Add Lesson</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowLessonModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <label className="form-label">Lesson Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Lesson Title"
              value={newLesson.title}
              onChange={(e) => setNewLesson({ ...newLesson, title: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Content Type</label>
            <select
              className="form-select"
              value={newLesson.content_type}
              onChange={(e) => setNewLesson({ ...newLesson, content_type: e.target.value })}
            >
              <option value="" disabled>Select Content Type</option>
              <option value="video">Video</option>
              <option value="rich-text">Rich Text</option>
              <option value="document">Document</option>
            </select>
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="Order"
              value={newLesson.order}
              onChange={(e) => setNewLesson({ ...newLesson, order: e.target.value })}
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label">Content File</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setNewLesson({ ...newLesson, content_file: e.target.files[0] })}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Content</label>
            <textarea
              className="form-control"
              placeholder="Content"
              value={newLesson.content}
              onChange={(e) => setNewLesson({ ...newLesson, content: e.target.value })}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn-modal-close btn btn-secondary" onClick={() => setShowLessonModal(false)}>
            Close
          </button>
          <button className="btn-modal-add btn btn-primary" onClick={handleAddLesson}>
            Add Lesson
          </button>
        </div>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};

export default EditCurriculum;
