import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer3 } from "../footer3";
import { Footer } from "../footer";
import { Header3 } from "../header3";
import { useSelector } from "react-redux";
import { HomeMain, bannerIllustration } from "../imagepath";
import { getCourses } from "../../api";
import { AuthContext } from "../../context/AuthContext";
import { CardImagePlaceHolder } from "../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";

export const Home3 = () => {
  const mobileSidebar = useSelector((state) => state.sidebarSlice.expandMenu);
  const [setValue] = useState(null);
  useEffect(() => {
    document.body.className = "home-three";

    return () => {
      document.body.className = "";
    };
  }, []);
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: mobileSidebar === "disabled" ? "#f7f7f7f7" : "#2b2838",
      width: "100%",
      height: "56px",
      minHeight: "56px",
      border: state.isFocused ? 0 : 0,
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      // borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        color: "black",
      },
      outline: "none",
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      borderRadius: "0",
      hyphens: "auto",
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided) => ({
      ...provided,
      backgroundColor: mobileSidebar === "disabled" ? "#fff" : "#000",
      color: mobileSidebar === "disabled" ? "#000" : "#fff",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: mobileSidebar === "disabled" ? "#FFDEDA" : "#2b2838",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  const [toggle, setToggle] = useState(1);

  const updateToggle = (id) => {
    setToggle(id);
  };
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const { isAuthenticated, userRole } = useContext(AuthContext);

  // set path based on user role
  const getDashboardPath = () => {
    if (userRole === "learner") {
      return "/student/student-dashboard";
    } else if (userRole === "superadmin") {
      return "/admin/admin-dashboard";
    } else if (userRole === "instructor") {
      return "/instructor/instructor-dashboard";
    }
    return "/";
  };
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        // Slice to get the first 4 courses
        setCourses(response.data.results.slice(0, 4));
      } catch (err) {
        setError("Failed to fetch courses");
      }
    };

    fetchCourses();
  }, []);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Header3 />
      {/* Home Banner */}
      <section
        className="home-three-slide d-flex align-items-center"
        style={{
          backgroundImage:
            mobileSidebar === "disabled" && "url(" + HomeMain + ")",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-xl-8 col-lg-10 col-md-12 col-12 text-center"
              data-aos="fade-down"
            >
              <div className="home-three-slide-face">
                <div className="home-three-slide-text">
                  <h1>Welcome to J’Funze</h1>
                  <h1>Solidaridad's e-learning platform!</h1>
                  <p>
                    Fostering individual and peer-to-peer learning, and
                    organizational knowledge sharing. Explore, engage, and
                    elevate your skills with seamless access to organizational
                    resources.
                  </p>
                  <ul className="list-inline">
                  {!isAuthenticated ? (
                    <>
                    <li className="list-inline-item">
                      <Link
                        className="nav-link login-three-head button"
                        to="/login"
                      >
                        <span>Login</span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="nav-link register-three-head"
                        to="/register"
                      >
                        Register
                      </Link>
                    </li>
                     </>
                    ) : (
                      <>
                    <li className="list-inline-item">
                      <Link
                        className="nav-link register-three-head"
                        to={getDashboardPath()}
                      >
                        Go To Dashboard
                      </Link>
                    </li>
                    </>
              )}
                  </ul>
                </div>
                <div className="banner-three-content"></div>
              </div>
            </div>
          </div>
        </div>
      </section> 

      {/* Home Banner

     

      {/* Courses */}
      <section className="home-three-courses">
        <div className="container">
          <div className="favourite-course-sec">
            <div className="row">
              <div
                className="home-three-head section-header-title"
                data-aos="fade-up"
              >
                <div className="row align-items-center d-flex justify-content-between">
                  <div className="col-lg-6 col-sm-8">
                    <h2>Featured Courses</h2>
                  </div>
                </div>
              </div>

              <div className="all-corses-main">
                <div className="tab-content">
                  {/* All */}

                  <div className="all-course">
                    <div className="row">
                      {error ? (
                        <div className="col-12">
                          <p>{error}</p>
                        </div>
                      ) : (
                        courses.map((course, index) => (
                          <div
                            className="col-xl-3 col-lg-6 col-md-6 col-12 mb-4"
                            data-aos="fade-up"
                            key={index}
                          >
                            <div
                              className="course-box-three"
                              style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }} /* Ensuring the card takes full height */
                            >
                              <div className="course-three-item">
                                <div className="course-three-img">
                                  <Link
                                    to={
                                      isAuthenticated
                                        ? `/course-details/${course.id}`
                                        : `/login`
                                    }
                                  >
                                    <img
                                      className="img-fluid"
                                      alt={course.title}
                                      src={`${API_URL}/${course.banner}`}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = CardImagePlaceHolder;
                                      }}
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Link>
                                </div>
                                <div
                                  className="course-three-content"
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="course-group-three">
                                    <div className="group-three-img">
                                      {/* Optional content here */}
                                    </div>
                                  </div>
                                  <div className="course-three-text">
                                    <Link
                                      to={
                                        isAuthenticated
                                          ? `/course-details/${course.id}`
                                          : `/login`
                                      }
                                    >
                                      <h3 className="title instructor-text">
                                        {course.title}
                                      </h3>
                                      <p className="clamped-text">
                                        {truncateText(course.outcomes, 150)}
                                      </p>
                                    </Link>
                                  </div>

                                  <div className="price-three-group d-flex align-items-center justify-content-between">
                                    <div className="price-three-view d-flex align-items-center"></div>
                                    <div className="price-three-time d-inline-flex align-items-center">
                                      <i className="fa-regular fa-clock me-2"></i>
                                      <span>{course.duration} hours</span>{" "}
                                      {/* Displaying the course duration */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>

                  {/* All */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Courses */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};
