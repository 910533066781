import React, { useState, useEffect, useContext } from "react";
import StudentSidebar from "../sidebar";
import StudentHeader from "../header";
import { Link } from "react-router-dom";
import {
  CardImagePlaceHolder
 } from "../../imagepath";
import Footer from "../../footer";
import { getUserEnrollments } from "../../../api";
import { AuthContext } from "../../../context/AuthContext";

const StudentCourses = () => {
  const [isClassAdded, setIsClassAdded] = useState([false]);
  const { userInfo } = useContext(AuthContext);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  const [activeCoursesCount, setActiveCoursesCount] = useState(0);
  const [completedCoursesCount, setCompletedCoursesCount] = useState(0);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // Fetch enrolled courses
  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      if (!userInfo) return;

      try {
        const response = await getUserEnrollments(userInfo.id); // Use userInfo.id to get enrollments
        const enrollments = response.data.results;
        setEnrolledCourses(enrollments); // Set enrolled courses

        // Calculate total, active, and completed courses
        const total = enrollments.length;
        const active = enrollments.filter(
          (enrollment) => enrollment.course.is_course_active
        ).length;
        const completed = enrollments.filter(
          (enrollment) => enrollment.progress === 100.0
        ).length;

        setTotalEnrollments(total);
        setActiveCoursesCount(active);
        setCompletedCoursesCount(completed);
      } catch (error) {
        toast.error("Failed to fetch enrolled courses");
      }
    };

    fetchEnrolledCourses();
  }, [userInfo]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };

  // Filter active courses
  const activeCourses = enrolledCourses.filter(
    (enrollment) => enrollment.course.is_course_active
  );

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Enrolled Courses"} />

      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* sidebar */}
            <StudentSidebar />
            {/* /Sidebar */}
            {/* Student Courses */}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Enrolled Courses</h3>
                  </div>
                  <div className="checkout-form pb-0">
                    <div className="wishlist-tab">
                      <ul className="nav">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className="active"
                            data-bs-toggle="tab"
                            data-bs-target="#enroll-courses"
                          >
                            Enrolled Courses ({totalEnrollments})
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            data-bs-toggle="tab"
                            data-bs-target="#active-courses"
                          >
                            Active Courses ({activeCoursesCount})
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            data-bs-toggle="tab"
                            data-bs-target="#complete-courses"
                          >
                            Completed Courses ({completedCoursesCount})
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="enroll-courses"
                      >
                        <div className="row">
                          {/* Check if there are no active courses */}
                          {activeCourses.length > 0 ? (
                            activeCourses.map((enrollment) => {
                              const { course, progress } = enrollment;
                              return (
                                <div
                                  className="col-xxl-4 col-md-6 d-flex"
                                  key={course.id}
                                >
                                  <div className="course-box flex-fill">
                                    <div className="product">
                                      <div className="product-img">
                                        <Link
                                          to={`/course-details/${course.id}`}
                                        >
                                          <img
                                            className="img-fluid"
                                            alt="Course"
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                              objectFit: "cover",
                                            }}
                                            src={`${API_URL}/${course.banner}`}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                CardImagePlaceHolder;
                                            }}
                                          />
                                        </Link>
                                      </div>
                                      <div className="product-content">
                                        <h3 className="title instructor-text">
                                          <Link
                                            to={`/course-details/${course.id}`}
                                          >
                                            {course.title}
                                          </Link>
                                        </h3>
                                        <h6>
                                          {truncateText(
                                            course.outcomes,
                                            150
                                          )}
                                        </h6>
                                        <div className="course-info d-flex align-items-center">
                                          <div className="rating-img d-flex align-items-center">
                                            <i
                                              className="fa fa-book course-card-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <p>10+ Lessons</p>
                                          </div>
                                          <div className="course-view d-flex align-items-center">
                                            <i
                                              className="fa fa-clock course-card-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <p>{course.duration} hours</p>
                                          </div>
                                        </div>
                                        <div className="progress-stip">
                                          <div
                                            className="progress-bar bg-success progress-bar-striped active-stip"
                                            style={{ width: `${progress}%` }}
                                          ></div>
                                        </div>
                                        <div className="student-percent">
                                          <p>{progress}% Completed</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p>No active courses found.</p>
                          )}
                          {/* /Course Grid */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dash-pagination">
                <div className="row align-items-center">
                  <div className="col-6">
                    <p>Page 1 of 2</p>
                  </div>
                  <div className="col-6">
                    <ul className="pagination">
                      <li className="active">
                        <Link to="#">1</Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="bx bx-chevron-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Student Courses */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </div>
  );
};

export default StudentCourses;
