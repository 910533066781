import React, { useEffect, useState, useContext } from "react";
import AdminSideBar from "../sidebar";
import { InstructorHeader } from "../../instructor/header";
import { getCourses, deleteCourse } from "../../../api/index";
import { Link } from "react-router-dom";
import { Icon1, Icon2, CardImagePlaceHolder } from "../../imagepath";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext.js";

const AdminCourse = () => {
  const [courses, setCourses] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

 
  // Fetch courses and filter by created_by once the userEmail is available
  useEffect(() => {
    if (userInfo) {
      const fetchCourses = async () => {
        try {
          const response = await getCourses();
          const filteredCourses = response.data.results.filter(
            (course) => course.created_by === userInfo.email
          );
          setCourses(filteredCourses);
        } catch (error) {
          toast.error("Failed to fetch courses");
        }
      };

      fetchCourses();
    }
  }, [userInfo]);

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Course"} />
      <>
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <AdminSideBar />
              {/* /Sidebar */}
              {/* Courses */}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-info">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>My Courses</h3>
                      <p>Manage your courses and its updates</p>
                    </div>
                    <div className="checkout-form pb-0">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="enroll-courses"
                        >
                          <div className="row">
                            {/* Course Grid */}
                            {courses.length > 0 ? (
                              courses.map((course) => (
                                <div
                                  className="col-xxl-4 col-md-6 d-flex"
                                  key={course.id}
                                >
                                  <div className="course-box flex-fill">
                                    <div className="product">
                                      <div className="product-img">
                                        <Link
                                          to={`/course-details/${course.id}`}
                                        >
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                              objectFit: "cover",
                                            }}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                CardImagePlaceHolder;
                                            }}
                                            className="img-fluid"
                                            alt="Course"
                                            src={`${API_URL}/${course.banner}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="product-content">
                                        <h3 className="title instructor-text">
                                          <Link
                                            to={`/course-details/${course.id}`}
                                          >
                                            {course.title}
                                          </Link>
                                        </h3>
                                        <div className="course-info d-flex align-items-center">
                                          <div className="rating-img d-flex align-items-center">
                                          <i
                                    className="fa fa-book course-card-icon"
                                    aria-hidden="true"
                                  ></i>
                                            <p>
                                              {course.lessonsCount}+ Lessons
                                            </p>
                                          </div>
                                          <div className="course-view d-flex align-items-center">
                                          <i
                                    className="fa fa-clock course-card-icon"
                                    aria-hidden="true"
                                  ></i>
                                            <p>{course.duration} hours</p>
                                          </div>
                                        </div>
                                        {/* <div className="course-edit-btn d-flex align-items-center justify-content-between"> */}
                                        <div className="manage-course  d-flex align-items-center">
                                          {/* <Link
                                            to={`/edit-course/${course.id}`}
                                          >
                                            <i className="bx bx-edit me-2" />{" "}
                                            Edit
                                          </Link>
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              handleDeleteClick(course.id)
                                            }
                                          >
                                            <i className="bx bx-trash me-2" />{" "}
                                            Delete
                                          </Link> */}

                                          <Link
                                            to={`/admin/course/${course.id}/manage/`}
                                            className="btn btn-primary"
                                          >
                                            Manage Course
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No courses found.</p>
                            )}
                            {/* /Course Grid */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <ToastContainer />
      </>
    </div>
  );
};

export default AdminCourse;
