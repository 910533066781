// src/context/authContext.js

import React, { createContext, useState, useEffect } from 'react';
import SessionExpiredModal from '../components/session';
import { googleLogin, googleRegister } from "../api"; // Import the Google login/register API methods

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null); 
  const [userInfo, setUserInfoState] = useState(null); // Renamed for internal state updating
  const [showModal, setShowModal] = useState(false); 

  // Custom setUserInfo to update both state and localStorage
  const setUserInfo = (newInfo) => {
    setUserInfoState(newInfo); // Update state
    localStorage.setItem('user_info', JSON.stringify(newInfo)); // Update localStorage
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('user_role'); 
    const userData = localStorage.getItem('user_info'); 
    
    setIsAuthenticated(!!token);
    setUserRole(role); 
    setUserInfoState(userData ? JSON.parse(userData) : null); // Use setUserInfoState here to avoid re-saving on load

    const tokenDuration = localStorage.getItem('access_token_duration');
    if (tokenDuration) {
      const timeoutId = setTimeout(() => {
        logoutUser(); 
        setShowModal(true); 
      }, tokenDuration * 1000);

      return () => clearTimeout(timeoutId); 
    }
  }, []);

  const loginUser = (data) => {
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    localStorage.setItem('access_token_duration', data.access_token_duration);
    localStorage.setItem('refresh_token_duration', data.refresh_token_duration);
    localStorage.setItem('user_role', data.user.role); 
    localStorage.setItem('user_info', JSON.stringify(data.user));
    
    setIsAuthenticated(true);
    setUserRole(data.user.role); 
    setUserInfo(data.user); // Use custom setUserInfo here
    setTimeout(logoutUser, data.access_token_duration * 1000);
  };

  const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_duration');
    localStorage.removeItem('refresh_token_duration');
    localStorage.removeItem('user_role'); 
    localStorage.removeItem('user_info');

    setIsAuthenticated(false);
    setUserRole(null); 
    setUserInfo(null);
  };

  // Google Login Handler
  const handleGoogleLogin = async (googleToken) => {
    try {
      const response = await googleLogin(googleToken);
      loginUser(response);
    } catch (error) {
      console.error("Google login failed:", error);
      throw error;
    }
  };

  // Google Register Handler
  const handleGoogleRegister = async (googleToken) => {
    try {
      const response = await googleRegister(googleToken);
      loginUser(response);
    } catch (error) {
      console.error("Google registration failed:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, userInfo, setUserInfo, loginUser, logoutUser, handleGoogleLogin,handleGoogleRegister }}>
      {children}
      <SessionExpiredModal show={showModal} onClose={() => setShowModal(false)} />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
