import React, { useEffect, useState, useContext } from "react";
import AdminSideBar from "../../../admin/sidebar";
import InstructorSideBar from "../../../instructor/sidebar";
import { InstructorHeader } from "../../../instructor/header";
import { getCourses, deleteCourse } from "../../../../api";
import { Link, useParams } from "react-router-dom";
import { Icon1, Icon2 } from "../../../imagepath.jsx";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext.js";

const ManageCourse = () => {
  const { courseId } = useParams();
  const { userInfo } = useContext(AuthContext);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // State for delete confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState(null);

  // Function to handle delete click
  const handleDeleteClick = (courseId) => {
    console.log(courseId);
    setCourseIdToDelete(courseId);
    setIsModalOpen(true);
  };

  // Function to confirm deletion
  const confirmDelete = async () => {
    try {
      await deleteCourse(courseId);
      // setCourses(courses.filter((course) => course.id !== courseIdToDelete));
      toast.success("Course deleted successfully");
    } catch (error) {
      toast.error("Failed to delete course");
    } finally {
      setIsModalOpen(false);
      setCourseIdToDelete(null);
    }
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Course"} />
      <>
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              {/* Conditionally render either InstructorSidebar or AdminSidebar */}
              {userInfo?.role === "superadmin" ? (
                <AdminSideBar />
              ) : (
                <InstructorSideBar />
              )}
              {/* /Sidebar */}
              {/* Courses */}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-info">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Manage Course</h3>
                      <p>Manage your course and its updates</p>
                    </div>
                    <div className="checkout-form pb-0">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="enroll-courses"
                        >
                          <div className="row justify-content-center manage-course">
                            <div className="col-lg-4 col-md-6 d-flex ">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to={`/admin/course/${courseId}/edit`}
              
                                    className="manage-course btn-primary"
                                  >
                                    Edit Course
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to={`/admin/course/${courseId}/manage-curriculum`}
                                    className="manage-course btn-primary"
                                  >
                                    Manage Curriculum
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to="#"
                                    onClick={() => handleDeleteClick(courseId)}
                                    className="manage-course btn-primary"
                                  >
                                    Delete Course
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to={`/course/${courseId}/assessment`}
                                    className="manage-course btn-primary"
                                  >
                                    Assessments
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to={`/course/${courseId}/enrollments`}
                                    className="manage-course btn-primary"
                                  >
                                    List  Students
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to="#"
                                    className="manage-course btn-primary"
                                  >
                                    Edit Assessment
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                            {/* <div className="col-lg-4 col-md-6 d-flex">
                              <div className="card dash-info flex-fill">
                                <div className="card-body">
                                  <Link
                                    to="#"
                                    className="manage-course btn-primary"
                                  >
                                    Delete Assessment
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bootstrap Modal for delete confirmation */}
        <div
          className={`modal fade ${isModalOpen ? "show" : ""}`}
          style={{ display: isModalOpen ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
          aria-hidden={!isModalOpen}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setIsModalOpen(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this course?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    </div>
  );
};

export default ManageCourse;
