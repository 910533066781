// src/api/interceptor.js
import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  logoutUser,
  setTokens,
} from "../utils/auth";
import { refreshToken } from "./index";

axios.interceptors.request.use(
  (config) => {
    // Check if the URL contains any part of the 'noAuthRequired' strings
    if (!noAuthRequired.some((url) => config.url.indexOf(url) !== -1)) {
      const token = getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const noAuthRequired = ["/api/users/login/", "/api/users/refresh-token/"];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the URL contains any part of the 'noAuthRequired' strings
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !noAuthRequired.some((url) => originalRequest.url.indexOf(url) !== -1)
    ) {
      originalRequest._retry = true;
      try {
        const refreshTokenValue = getRefreshToken();
        const response = await refreshToken(refreshTokenValue);
        setTokens(response.data.access, response.data.refresh);
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.access}`;
        return axios(originalRequest);
      } catch (err) {
        logoutUser();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
