import React from "react";
import { Link } from "react-router-dom";
import { Icon19, Icon20, Icon21, logo, logoSixPng } from "../imagepath";

export const Footer = () => {
  return (
    <footer className="footer">
      {/* Footer Top */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
          <div className="col-lg-9 col-md-6 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-about">
                  <div className="footer-three-logo">
                    <img className="logo-image-footer" src={logoSixPng} alt="logo" />
                  </div>
                  <div className="footer-three-about">
                    <p>
                    J'Funze: Empowering staff with tailored learning and personalized assessments for seamless role transitions and essential skills development.
                    Join us and become a key player in shaping our organization's success!
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-3 col-12" >
              <div className="footer-widget-three footer-menu-three">
              <div className="newsletter-title">
                  <div className="privacy-policy-three">
                  <ul>
                    <li>
                    <Link to="/deposit-student-dashboard"> Dashboard</Link>
                      <Link to="/support">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/term-condition">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                  </div>
                </div> 
              </div>
              </div> */}

<div className="col-lg-3 col-md-3 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-menu-three footer-three-right">
                  <h6 className="footer-three-title">Quick Links</h6>
                  <ul>
                    <li>
                      <Link to="/support">Contact Us</Link>
                    </li>
                    
                    <li>
                    <Link to="/term-condition">Terms & Condition</Link>
                    </li>
                    <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                {/* Footer Widget */}
              </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-three-bottom" data-aos="fade-up">
        <div className="container">
          {/* Copyright */}
          <div className="copyright-three">
            <div className="row">
              <div className="col-md-12">
                <div className="privacy-policy-three">
                  
                </div>
                <div className="copyright-text-three">
                  <p className="mb-0">
                    &copy; 2024 Solidaridad. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default Footer;
