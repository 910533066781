import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo, logoJifunzeYellowPng,logoBlack } from "../../../imagepath";
import HeadModule from "../module";
import { AuthContext } from "../../../../context/AuthContext"; 
// eslint-disable-next-line react/prop-types
const CourseHeader = ({ activeMenu }) => {
  const { isAuthenticated, userRole } = useContext(AuthContext); 
  const navigate = useNavigate(); 
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };

  // set path based on user role
  const getDashboardPath = () => {
    if (userRole === "learner") {
      return "/student/student-dashboard";
    } else if (userRole === "superadmin") {
      return "/admin/admin-dashboard";
    } else if (userRole === "instructor") {
      return "/instructor/instructor-dashboard";
    }
    return "/"; 
  };

  return (
    <>
      <header className=" header-yellow header header-page">
        <div className="header-fixed">
          <nav className="navbar navbar-expand-lg header-nav header-nav-yellow scroll-sticky">
            <div className="container ">
              <div className="navbar-header">
                <Link id="mobile_btn" to="#" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/home" className="navbar-brand logo">
                  <img src={logoJifunzeYellowPng} className="img-fluid" alt="Logo" />
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/home" className="menu-logo">
                    <img src={logoJifunzeYellowPng} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li>
                    <Link className="header-links" to="/">Home</Link>
                  </li>
                  {/* <li>
                    <Link  to={getDashboardPath()}>My Dashboard</Link>
                  </li> */}
                  <li className="login-link">
                    <Link to="/login" onClick={hideMobileMenu}>
                      Login / Signup
                    </Link>
                  </li>
                </ul>
              </div>
              <Link to="/home" className="navbar-brand logo">
                <img src={logoBlack} className="img-fluid" alt="Logo" />
              </Link>
              <HeadModule />
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default CourseHeader;
