import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Carousel from "react-bootstrap/Carousel";
import Footer from "../../../footer";
import PageHeader from "../../header";
import CourseHeader from "../header";
import "./index.css";
import {
  getCourseDetails,
  getCourseProgress,
  getCourseAssessments,
  submitAssessment,
  updateLessonProgress
} from "../../../../api";

const CourseLesson = () => {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState(null);
  const [error, setError] = useState(null);
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [answers, setAnswers] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoCompleted, setVideoCompleted] = useState(false); // Track video completion
  const [videoTimestamp, setVideoTimestamp] = useState(0); // Track video timestamp
  const API_URL = process.env.REACT_APP_MEDIA_URL;
  const [courseProgress, setCourseProgress] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [results, setResults] = useState(null);

  // Define `isOnAssessmentPage` and `isOnAssessmentResults` in render context
  const isOnAssessmentPage = Boolean(selectedAssessment);
  const isOnAssessmentResults = Boolean(results);

  const [lastLessonId, setLastLessonId] = useState(null);
  const [lastSectionIndex, setLastSectionIndex] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const courseResponse = await getCourseDetails(courseId);
        setCourseDetails(courseResponse);

        const progressResponse = await getCourseProgress(courseId);
        setCourseProgress(progressResponse);

        const assessmentsResponse = await getCourseAssessments(courseId);
        setAssessments(assessmentsResponse);

        if (
          courseResponse.sections.length > 0 &&
          courseResponse.sections[0].lessons.length > 0
        ) {
          setSelectedLesson(courseResponse.sections[0].lessons[0]);
        }

        setDropdowns(new Array(courseResponse.sections.length).fill(false));
      } catch (error) {
        setError(error);
        console.error("Error fetching course details and assessments:", error);
      }
    };

    fetchCourseData();
  }, [courseId]);


  const handleLessonProgressUpdate = async (isCompleted = false) => {
    if (selectedLesson) {
      const lessonData = {
        lesson_id: selectedLesson.id,
        is_completed: isCompleted,
      };

      if (selectedLesson.content_type === "video") {
        lessonData.video_timestamp = videoTimestamp;
      } else if (
        selectedLesson.content_type === "document" ||
        selectedLesson.content_type === "rich-text"
      ) {
        lessonData.last_image_index = currentSlide;
      }

      try {
        await updateLessonProgress(courseId, lessonData);
        const progressResponse = await getCourseProgress(courseId);
        setCourseProgress(progressResponse);
      } catch (error) {
        console.error("Error updating lesson progress:", error);
        alert("Error updating progress");
      }
    }
  };


  const handleSlideChange = (selectedIndex) => {
    setCurrentSlide(selectedIndex);
    if (
      selectedLesson.content_type === "document" &&
      selectedIndex === getLessonContentUrls(selectedLesson).length - 1
    ) {
      handleLessonProgressUpdate(true);
    } else {
      handleLessonProgressUpdate();
    }
  };

  const handleVideoEnd = () => {
    setVideoCompleted(true);
    handleLessonProgressUpdate(true);
  };

  const handleVideoTimeUpdate = (event) => {
    setVideoTimestamp(event.target.currentTime);
  };


  const toggleDropdown = (index) => {
    setDropdowns((prev) => {
      const newDropdowns = [...prev];
      newDropdowns[index] = !newDropdowns[index];
      return newDropdowns;
    });
  };

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
    setSelectedAssessment(null);
    setCurrentSlide(0);
    setVideoCompleted(false); // Reset video completion when selecting a new lesson
  };

  const handleAssessmentClick = (assessment) => {
    // Find the section containing the assessment by using assessment.lesson
    const sectionIndex = courseDetails.sections.findIndex((section) =>
      section.lessons.some((lesson) => lesson.id === assessment.lesson)
    );

    if (sectionIndex !== -1) {
      const section = courseDetails.sections[sectionIndex];
      const lessonIndex = section.lessons.findIndex(
        (lesson) => lesson.id === assessment.lesson
      );

      console.log("I am here", sectionIndex, lessonIndex);

      // Set lastSectionIndex to this section and lastLessonId to the lesson before the assessment
      setLastSectionIndex(sectionIndex);
      setLastLessonId(
        lessonIndex > 0 ? section.lessons[lessonIndex - 1].id : null
      );
    }

    setSelectedAssessment(assessment);
    setSelectedLesson(null);
    setAnswers({});
    setResults(null);
    setCurrentQuestionIndex(0); // Reset to the first question
  };

  const getLessonContentUrls = (lesson) => {
    if (lesson.content_type === "document") {
      return JSON.parse(lesson.content_file).map(
        (file) => `${API_URL}/${file}`
      );
    } else if (lesson.content_type === "video") {
      return [`${API_URL}/${lesson.content_file}`];
    } else if (lesson.content_type === "rich-text") {
      return [lesson.content];
    }
    return [];
  };

  const handleAnswerChange = (questionId, value, isChoice = false) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: isChoice
        ? { selected_answer_ids: value }
        : { answer_text: value },
    }));
  };

  const handleSubmitAssessment = async () => {
    const unansweredQuestions = selectedAssessment.questions.filter(
      (question) => {
        if (
          ["single_choice", "multiple_choice"].includes(question.question_type)
        ) {
          return (
            !answers[question.id]?.selected_answer_ids ||
            answers[question.id].selected_answer_ids.length === 0
          );
        } else if (["short_text", "essay"].includes(question.question_type)) {
          return (
            !answers[question.id]?.answer_text ||
            answers[question.id].answer_text.trim() === ""
          );
        }
        return true;
      }
    );

    if (unansweredQuestions.length > 0) {
      alert("Please answer all the questions before submitting.");
      return;
    }

    try {
      const response = await submitAssessment(
        selectedAssessment.id,
        Object.keys(answers).map((questionId) => ({
          question_id: parseInt(questionId),
          ...answers[questionId],
        }))
      );

      if (response.is_auto_graded) {
        setResults(response);
      } else {
        alert("Assessment submitted and will be graded manually.");
      }

      setSelectedAssessment(null);
    } catch (error) {
      console.error("Error submitting assessment:", error);
      alert("Error submitting assessment");
    }
  };

  const renderResults = (results) => (
    <div
      className="results text-start p-4 mx-auto"
      style={{ maxWidth: "600px" }}
    >
      <h4>Assessment Results</h4>
      <p>Total Marks: {results.total_marks}</p>
      <p>Obtained Marks: {results.obtained_marks}</p>
      <p>Percentage: {results.percentage}%</p>
      <p>Status: {results.passed ? "Passed" : "Failed"}</p>
    </div>
  );

  const renderAssessment = (assessment) => {
    const question = assessment.questions[currentQuestionIndex];

    if (!question) {
      // Return a loading message or an empty div if the question is not available
      return <div>No questions available</div>;
    }

    return (
      <div className="assessment-content text-center p-4 mx-auto">
        <h4>{assessment.title}</h4>
        <p>{assessment.description}</p>
        <div className="progress my-3">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${
                ((currentQuestionIndex + 1) / assessment.questions.length) * 100
              }%`,
            }}
            aria-valuenow={currentQuestionIndex + 1}
            aria-valuemin="0"
            aria-valuemax={assessment.questions.length}
          >
            Question {currentQuestionIndex + 1} of {assessment.questions.length}
          </div>
        </div>

        <div className="question-block mb-4 text-start">
          <p>
            <strong>
              {currentQuestionIndex + 1}. {question.question_text}
            </strong>
          </p>
          {question.question_type === "single_choice" && (
            <div className="mb-3">
              {question.answers.map((answer, idx) => (
                <div key={answer.id} className="form-check">
                  <input
                    type="radio"
                    id={`question_${question.id}_answer_${answer.id}`}
                    name={`question_${question.id}`}
                    value={answer.id}
                    className="form-check-input"
                    onChange={(e) =>
                      handleAnswerChange(question.id, [answer.id], true)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`question_${question.id}_answer_${answer.id}`}
                  >
                    {String.fromCharCode(65 + idx)}. {answer.answer_text}
                  </label>
                </div>
              ))}
            </div>
          )}

          {question.question_type === "multiple_choice" && (
            <div className="mb-3">
              {question.answers.map((answer, idx) => (
                <div key={answer.id} className="form-check">
                  <input
                    type="checkbox"
                    id={`question_${question.id}_answer_${answer.id}`}
                    name={`question_${question.id}`}
                    value={answer.id}
                    className="form-check-input"
                    onChange={(e) =>
                      handleAnswerChange(
                        question.id,
                        [
                          ...(answers[question.id]?.selected_answer_ids || []),
                          answer.id,
                        ],
                        true
                      )
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`question_${question.id}_answer_${answer.id}`}
                  >
                    {String.fromCharCode(65 + idx)}. {answer.answer_text}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between mt-3">
          <button
            className="btn btn-secondary"
            onClick={() => setCurrentQuestionIndex((prev) => prev - 1)}
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </button>
          {currentQuestionIndex < assessment.questions.length - 1 ? (
            <button
              className="btn btn-primary"
              onClick={() => setCurrentQuestionIndex((prev) => prev + 1)}
            >
              Next
            </button>
          ) : (
            <button
              className="btn btn-success"
              onClick={handleSubmitAssessment}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    );
  };

  const navigateContent = (direction) => {
    console.log("navigateContent triggered on direction:", direction);

    const isOnLessonContent = Boolean(selectedLesson);
    const isOnAssessmentResults = Boolean(results);

    // Use lastSectionIndex directly when on assessment results page
    const sectionIndex = isOnLessonContent
      ? courseDetails.sections.findIndex((section) =>
          section.lessons.some((lesson) => lesson.id === selectedLesson.id)
        )
      : lastSectionIndex;

    console.log("Section index:", sectionIndex);
    console.log("Selected lesson:", selectedLesson);
    console.log("Last lesson ID:", lastLessonId);
    console.log("Results available:", results);

    if (sectionIndex === -1 || !courseDetails.sections[sectionIndex]) {
      console.log("Invalid section index or section not found");
      return;
    }

    const currentSection = courseDetails.sections[sectionIndex];
    const lessonIndex = isOnLessonContent
      ? currentSection.lessons.findIndex(
          (lesson) => lesson.id === selectedLesson.id
        )
      : currentSection.lessons.findIndex(
          (lesson) => lesson.id === lastLessonId
        );

    const isLastLesson = lessonIndex === currentSection.lessons.length - 1;
    const isFirstLesson = lessonIndex === 0;

    if (direction === "next") {
      if (isOnAssessmentResults) {
        console.log("On assessment results page, navigating to next section");
        if (sectionIndex < courseDetails.sections.length - 1) {
          const nextSection = courseDetails.sections[sectionIndex + 1];
          if (nextSection.lessons.length > 0) {
            setSelectedLesson(nextSection.lessons[0]);
            setResults(null); // Clear results for navigation
            setCurrentSlide(0);
            setVideoCompleted(false);
            setLastSectionIndex(sectionIndex + 1); // Update to the new section index
            console.log("Navigated to next section's first lesson");
          }
        }
      } else if (isOnLessonContent) {
        if (!isLastLesson && lessonIndex !== -1) {
          setSelectedLesson(currentSection.lessons[lessonIndex + 1]);
          setCurrentSlide(0);
          setVideoCompleted(false);
          console.log("Navigated to next lesson in current section");
        } else if (sectionIndex < courseDetails.sections.length - 1) {
          const nextSection = courseDetails.sections[sectionIndex + 1];
          if (nextSection.lessons.length > 0) {
            setSelectedLesson(nextSection.lessons[0]);
            setCurrentSlide(0);
            setVideoCompleted(false);
            setLastSectionIndex(sectionIndex + 1); // Update to the new section index
            console.log("Navigated to next section's first lesson");
          }
        }
      }
    } else if (direction === "prev") {
      if (isOnAssessmentResults) {
        console.log(
          "On assessment results page, navigating to previous section"
        );
        if (sectionIndex > 0) {
          const prevSection = courseDetails.sections[sectionIndex - 1];
          if (prevSection.lessons.length > 0) {
            setSelectedLesson(
              prevSection.lessons[prevSection.lessons.length - 1]
            );
            setResults(null); // Clear results for navigation
            setCurrentSlide(0);
            setVideoCompleted(false);
            setLastSectionIndex(sectionIndex - 1); // Update to the new section index
            console.log("Navigated to previous section's last lesson");
          }
        }
      } else if (isOnLessonContent) {
        if (!isFirstLesson && lessonIndex !== -1) {
          setSelectedLesson(currentSection.lessons[lessonIndex - 1]);
          setCurrentSlide(0);
          setVideoCompleted(false);
          console.log("Navigated to previous lesson in current section");
        } else if (sectionIndex > 0) {
          const prevSection = courseDetails.sections[sectionIndex - 1];
          if (prevSection.lessons.length > 0) {
            setSelectedLesson(
              prevSection.lessons[prevSection.lessons.length - 1]
            );
            setCurrentSlide(0);
            setVideoCompleted(false);
            setLastSectionIndex(sectionIndex - 1); // Update to the new section index
            console.log("Navigated to previous section's last lesson");
          }
        }
      }
    }
  };

  const renderCarousel = (contentUrls) => {
    if (contentUrls.length > 0) {
      return (
        <Carousel
          data-bs-theme="dark"
          interval={null}
          activeIndex={currentSlide}
          onSelect={handleSlideChange}
          controls={true}
          indicators={false}
          nextIcon={
            currentSlide < contentUrls.length - 1 ? (
              <span aria-hidden="true" className="carousel-control-next-icon" />
            ) : null
          }
        >
          {contentUrls.map((content, index) => (
            <Carousel.Item key={index}>
              {selectedLesson.content_type === "video" ? (
                <video
                  controls
                  onEnded={handleVideoEnd}
                  onTimeUpdate={handleVideoTimeUpdate} // Track video timestamp
                >
                  <source src={content} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : selectedLesson.content_type === "rich-text" ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              ) : (
                <img src={content} alt={`Slide ${index}`} />
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      );
    }

    return <div>No content available for this lesson.</div>;
  };

  const showGoToAssessmentButton =
    selectedLesson &&
    assessments.some((assessment) => assessment.lesson === selectedLesson.id) &&
    ((selectedLesson.content_type === "document" &&
      currentSlide === getLessonContentUrls(selectedLesson).length - 1) ||
      (selectedLesson.content_type === "video" && videoCompleted) ||
      (selectedLesson.content_type === "rich-text" && true));

  if (error) {
    return <div>Error fetching course details: {error.message}</div>;
  }

  if (!courseDetails) {
    return <div>Loading...</div>;
  }

  const sectionIndex = courseDetails.sections.findIndex((section) =>
    section.lessons.some((lesson) => lesson.id === selectedLesson?.id)
  );

  const isFirstLesson =
    selectedLesson &&
    sectionIndex === 0 &&
    courseDetails.sections[sectionIndex].lessons[0].id === selectedLesson.id;

  return (
    <div className="main-wrapper">
      <CourseHeader />
      <div className="row lesson-row">
        <div className="col-lg-4 col-md-6 lesson-content-card">
          <div className="lesson-group curriculum-course-title">
            <h5 className="course-title curriculum-content-header">
              {courseDetails.title}
            </h5>
          </div>
          <div className="progress-stip">
            <div
              className="progress-bar bg-success progress-bar-striped active-stip"
              role="progressbar"
              style={{ width: `${courseProgress?.progress_percentage || 0}%` }}
              aria-valuenow={courseProgress?.progress_percentage}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <span className="percentage-label">
                {courseProgress?.progress_percentage}%
              </span>
            </div>
          </div>

          {/* <div className="progress-stip">
            <div className="progress-bar bg-success progress-bar-striped active-stip" />
          </div>
          <div className="student-percent lesson-percent">
            <p>
              0hrs<span>0%</span>
            </p>
          </div> */}

          <div className="lesson-group">
            {courseDetails?.sections.map((section, index) => (
              <div className="course-card" key={section.id}>
                <h6 className="cou-title">
                  <Link
                    className="collapsed"
                    to={`#section${index}`}
                    onClick={() => toggleDropdown(index)}
                  >
                    {section.title}{" "}
                    <span>{section.lessons.length} Lessons</span>
                  </Link>
                </h6>
                <Collapse in={dropdowns[index]}>
                  <div
                    id={`section${index}`}
                    className="card-collapse collapse"
                  >
                    <ul>
                      {section.lessons.map((lesson) => (
                        <li
                          key={lesson.id}
                          onClick={() => handleLessonClick(lesson)}
                        >
                          <p>{lesson.title}</p>
                          <div>
                            {lesson.content_type === "document" && (
                              <i className="bi bi-file-earmark-text"></i>
                            )}
                            {lesson.content_type === "video" && (
                              <i className="bi bi-play-circle"></i>
                            )}
                            {lesson.content_type === "rich-text" && (
                              <i className="bi bi-file-text"></i>
                            )}
                          </div>
                        </li>
                      ))}
                      {assessments
                        .filter((assessment) =>
                          section.lessons.some(
                            (lesson) => lesson.id === assessment.lesson
                          )
                        )
                        .map((assessment) => (
                          <li
                            key={assessment.id}
                            onClick={() => handleAssessmentClick(assessment)}
                          >
                            <p>{assessment.title} (Assessment)</p>{" "}
                            <i className="bi bi-patch-question"></i>
                          </li>
                        ))}
                    </ul>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>

        <div className="col-lg-8 col-md-6">
          <div className="student-widget lesson-introduction">
            <div className="lesson-widget-group">
              {selectedLesson ? (
                <>
                  <h4 className="tittle">{selectedLesson.title}</h4>
                  {renderCarousel(getLessonContentUrls(selectedLesson))}
                  {showGoToAssessmentButton && (
                    <button
                      className="btn btn-primary mt-4"
                      onClick={() =>
                        handleAssessmentClick(
                          assessments.find(
                            (assessment) =>
                              assessment.lesson === selectedLesson.id
                          )
                        )
                      }
                    >
                      Go to Assessment
                    </button>
                  )}
                </>
              ) : results ? (
                renderResults(results)
              ) : selectedAssessment ? (
                renderAssessment(selectedAssessment)
              ) : (
                <div>Select a lesson or assessment to view its content.</div>
              )}
              {/* Show Previous and Next Section buttons only when NOT on the assessment page */}
              {!isOnAssessmentPage && (
                <div className="d-flex justify-content-between mt-4">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      console.log("Previous Section button clicked");
                      navigateContent("prev");
                    }}
                    disabled={
                      sectionIndex === 0 &&
                      isFirstLesson &&
                      !isOnAssessmentResults
                    }
                  >
                    Previous Section
                  </button>

                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      console.log("Next Section button clicked");
                      navigateContent("next");
                    }}
                  >
                    Next Section
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLesson;
