import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCourses } from "../../../../api"; 
import { Icon2 } from "../../../imagepath";

const GridInnerPage = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_MEDIA_URL
  const navigate = useNavigate(); 
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(response.data.results); 
      } catch (err) {
        setError("Failed to fetch courses");
      }
    };

    fetchCourses();
  }, []);

  const handleCourseClick = (courseId) => {
    // Use navigate for redirection
    navigate(`/course-details/${courseId}`);
  };

  return (
    <>
      <div className="row">
        {error ? (
          <div className="col-12">
            <p>{error}</p> {/* Display error message */}
          </div>
        ) : (
          courses.map((course, index) => (
            <div className="col-lg-4 col-md-6 d-flex" key={index}>
              <div className="course-box course-design d-flex">
                <div className="product">
                  <div className="product-img">
                    <Link to={`/course-details/${course.id}`}>
                      <img
                        className="img-fluid"
                        alt={course.title}
                        src={`${API_URL}/${course.banner}`}
                      />
                    </Link>
                  </div>
                  <div className="product-content">
                    <h3 className="title">
                      <Link to={`/course-details/${course.id}`}>
                        {course.title}
                      </Link>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="course-view d-flex align-items-center">
                        <img src={Icon2} alt="Duration Icon" />
                        <p>{course.duration}</p> {/* Display course duration */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default GridInnerPage;
