import React, { useEffect, useState } from "react";
import Footer from "../../footer";
import { InstructorHeader } from "../../instructor/header";
import { CardImagePlaceHolder } from "../../imagepath";
import AdminSidebar from "../sidebar";
import { getCourses, listAllUsers, getCourseEnrollments, getUserSubmissions } from "../../../api";
import { Link } from "react-router-dom";

export const AdminDashboard = () => {
  const [recentCourses, setRecentCourses] = useState([]);
  const [totalCourses, setTotalCourses] = useState(0);
  const [activeCourses, setActiveCourses] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  const [completedCourses, setCompletedCourses] = useState(0);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all courses
        const courseResponse = await getCourses();
        const courses = courseResponse.data.results;

        // Update total courses count
        setTotalCourses(courses.length);

        // Filter active courses
        const activeCoursesList = courses.filter(course => course.is_course_active);
        setActiveCourses(activeCoursesList.length);

        // Sort courses by created_at date and get the last 5
        const sortedCourses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setRecentCourses(sortedCourses.slice(0, 5));

        // Fetch total users
        const userResponse = await listAllUsers();
        setTotalUsers(userResponse.length);

        // Calculate total enrollments and completed courses for active courses
        let enrollmentsCount = 0;
        let completedCoursesCount = 0;
        for (const course of activeCoursesList) {
          const enrollmentResponse = await getCourseEnrollments(course.id, {}, localStorage.getItem("access_token"));
          enrollmentsCount += enrollmentResponse.data.count;

          // Check for enrollments with 100% progress
          const completedEnrollments = enrollmentResponse.data.results.filter(enrollment => enrollment.progress === 100);
          completedCoursesCount += completedEnrollments.length;
        }
        setTotalEnrollments(enrollmentsCount);
        setCompletedCourses(completedCoursesCount);

        // Fetch total assessment submissions
        const submissionResponse = await getUserSubmissions({}, localStorage.getItem("access_token"));
        setTotalSubmissions(submissionResponse.count); // Set total submissions count

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Dashboard"} />
      <div className="page-content">
        <div className="container">
          <div className="row admin-row">
            {/* Sidebar */}
            <AdminSidebar />
            <div className="col-xl-9 col-lg-9">
              {/* Dashboard Grid */}
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Enrolled Courses</h5>
                      <h2>{totalEnrollments}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Active Courses</h5>
                      <h2>{activeCourses}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Completed Courses</h5>
                      <h2>{completedCourses}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Total Students</h5>
                      <h2>{totalUsers}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Total Courses</h5>
                      <h2>{totalCourses}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="card dash-info flex-fill">
                    <div className="card-body">
                      <h5>Assessment Submissions</h5>
                      <h2>{totalSubmissions}</h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Dashboard Grid */}
              <div className="instructor-course-table">
                <div className="dashboard-title">
                  <h4>Recently Created Courses</h4>
                </div>
                <div className="table-responsive custom-table">
                  {/* Referred Users */}
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>Courses</th>
                        <th>Enrolled</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentCourses.map((course) => (
                        <tr key={course.id}>
                          <td>
                            <div className="table-course-detail">
                              <Link to={`/course-details/${course.id}`} className="course-table-img">
                                <span>
                                  <img
                                    src={`${API_URL}/${course.icon}`}
                                    alt="Img"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = CardImagePlaceHolder;
                                    }}
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </span>
                                {course.title}
                              </Link>
                            </div>
                          </td>
                          <td>0</td>
                          <td>{course.is_course_active ? "Published" : "Unpublished"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Student Dashboard */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </div>
  );
};
