import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import AdminSideBar from "../../../admin/sidebar";
import InstructorSideBar from "../../../instructor/sidebar";
import { InstructorHeader } from "../../../instructor/header";
import {
  getCourseDetails,
  createAssessment,
  addQuestionToAssessment,
  getCourseAssessments,
  editAssessment,
  deactivateAssessment,
  modifyQuestion,
  deleteQuestion,
} from "../../../../api";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext.js";
import "bootstrap/dist/css/bootstrap.min.css";

const AddAssessments = () => {
  const { courseId } = useParams();
  const { userInfo } = useContext(AuthContext);
  const [courseDetails, setCourseDetails] = useState({});
  const [assessments, setAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null); // For viewing assessments
  const [newAssessment, setNewAssessment] = useState({
    title: "",
    description: "",
    is_mandatory: false,
    pass_mark: 0,
  });
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [questionData, setQuestionData] = useState({
    question_text: "",
    question_type: "single_choice",
    marks: 0,
    answers: [],
  });
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showViewAssessmentModal, setShowViewAssessmentModal] = useState(false); // For viewing assessments
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null); // New state for selected section
  const [filteredLessons, setFilteredLessons] = useState([]); // Stores lessons filtered by selected section

  // Fetch course assessments
  const fetchAssessments = async () => {
    try {
      const data = await getCourseAssessments(courseId);
      setAssessments(data); // Update the assessments state with the fetched data
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch assessments");
    }
  };

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const data = await getCourseDetails(courseId);
        setCourseDetails(data);
        fetchAssessments(); // Fetch assessments when the component loads
      } catch (error) {
        toast.error("Failed to fetch course details");
      }
    };
    fetchCourseDetails();
  }, [courseId]);

  // filtered lessons whenever selectedSection changes
  useEffect(() => {
    if (selectedSection) {
      const sectionId = parseInt(selectedSection, 10); // Parse to ensure compatibility with numeric IDs
      const section = courseDetails.sections?.find(
        (section) => section.id === sectionId
      );
      setFilteredLessons(section ? section.lessons : []);
      setSelectedLesson(null); // Reset selected lesson when section changes
    } else {
      setFilteredLessons([]);
    }
  }, [selectedSection, courseDetails.sections]);

  const handleCreateAssessment = async () => {
    try {
      await createAssessment(
        { ...newAssessment, course: courseId, lesson: selectedLesson },
        userInfo.token
      );
      toast.success("Assessment created successfully!");
      setShowAssessmentModal(false);
      fetchAssessments(); // Fetch updated assessments after creating a new one
    } catch (error) {
      toast.error("Failed to create assessment");
    }
  };

  const handleAddQuestion = async () => {
    try {
      await addQuestionToAssessment(
        selectedAssessment.id,
        questionData,
        userInfo.token
      );
      toast.success("Question added successfully!");
      setShowQuestionModal(false);
      fetchUpdatedAssessment(selectedAssessment.id); // Fetch the updated assessment and update the state
    } catch (error) {
      toast.error("Failed to add question");
    }
  };

  // Fetch the updated assessment from the list after adding a new question
  const fetchUpdatedAssessment = async (assessmentId) => {
    try {
      await fetchAssessments(); // Fetch the entire list of assessments again
      const updatedAssessment = assessments.find(
        (assessment) => assessment.id === assessmentId
      );
      setSelectedAssessment(updatedAssessment); // Set the updated assessment in the state
    } catch (error) {
      toast.error("Failed to refresh the assessment");
    }
  };

  // Add an answer for single choice or multiple choice questions
  const addAnswer = () => {
    setQuestionData({
      ...questionData,
      answers: [
        ...questionData.answers,
        { answer_text: "", is_correct: false },
      ],
    });
  };

  // Unified Handle Answer Change function for both add and edit modes
  const handleAnswerChange = (index, field, value, mode = "add") => {
    const answers =
      mode === "add" ? questionData.answers : questionToEdit.answers;
    const updatedAnswers = [...answers];

    if (
      field === "is_correct" &&
      questionData.question_type === "single_choice"
    ) {
      // For single choice, ensure only one correct answer
      updatedAnswers.forEach((answer, idx) => {
        answer.is_correct = idx === index ? value : false;
      });
    } else {
      updatedAnswers[index][field] = value;
    }

    if (mode === "add") {
      setQuestionData({ ...questionData, answers: updatedAnswers });
    } else {
      setQuestionToEdit({ ...questionToEdit, answers: updatedAnswers });
    }
  };

  // Reset newAssessment and open "Add Assessment" modal
  const openAddAssessmentModal = () => {
    setNewAssessment({
      title: "",
      description: "",
      is_mandatory: false,
      pass_mark: 0,
    });
    setSelectedSection(null); // Reset selected section
    setFilteredLessons([]); // Reset lessons
    setSelectedLesson(null); // Reset selected lesson
    setShowAssessmentModal(true);
  };

  // Reset questionData and open "Add Question" modal
  const openAddQuestionModal = () => {
    setQuestionData({
      question_text: "",
      question_type: "single_choice",
      marks: 0,
      answers: [],
    });
    setShowQuestionModal(true);
  };

  const viewAssessment = async (assessment) => {
    await fetchUpdatedAssessment(assessment.id); // Fetch the updated assessment before showing the modal
    setShowViewAssessmentModal(true);
  };

  const handleEditAssessment = async () => {
    try {
      // Only send edited fields in the request body
      const updatedFields = {};
      if (selectedAssessment.title !== selectedAssessment.initialTitle) {
        updatedFields.title = selectedAssessment.title;
      }
      if (selectedAssessment.description !== selectedAssessment.initialDescription) {
        updatedFields.description = selectedAssessment.description;
      }
      if (selectedAssessment.pass_mark !== selectedAssessment.initialPassMark) {
        updatedFields.pass_mark = selectedAssessment.pass_mark;
      }
      if (selectedLesson && selectedLesson !== selectedAssessment.initialLesson) {
        updatedFields.lesson = selectedLesson;
      }
      if (Object.keys(updatedFields).length > 0) {
        await editAssessment(selectedAssessment.id, updatedFields, userInfo.token);
        toast.success("Assessment updated successfully!");
      } else {
        toast.info("No changes detected.");
      }
      setShowEditModal(false);
      fetchAssessments();
    } catch (error) {
      toast.error("Failed to update assessment");
    }
  };


  const handleDeleteAssessment = async () => {
    try {
      await deactivateAssessment(selectedAssessment.id, userInfo.token);
      
      toast.success("Assessment deleted successfully!");
      setShowDeleteModal(false);
      fetchAssessments();
    } catch (error) {
      toast.error("Failed to delete assessment");
    }
  };

  const openEditQuestionModal = (question) => {
    setQuestionToEdit({
      ...question,
      initialQuestionText: question.question_text,
      initialMarks: question.marks,
      // initialAnswers: [...question.answers], // store initial answers for comparison

      initialAnswers: question.answers.map((answer) => ({
        answer_text: answer.answer_text,
        is_correct: answer.is_correct,
      })),
    });
    setShowEditQuestionModal(true);
    setShowViewAssessmentModal(false);
  };

  // Edit question submission
  const handleEditQuestion = async () => {
    try {
      // Only send fields that have been changed
      const updatedFields = {};
      if (questionToEdit.question_text !== questionToEdit.initialQuestionText) {
        updatedFields.question_text = questionToEdit.question_text;
      }
      if (questionToEdit.marks !== questionToEdit.initialMarks) {
        updatedFields.marks = questionToEdit.marks;
      }

      // Check if any answer's is_correct status or text has changed
      const answersUpdated = questionToEdit.answers.some((answer, idx) => {
        const initialAnswer = questionToEdit.initialAnswers[idx];
        return (
          answer.answer_text !== initialAnswer.answer_text ||
          answer.is_correct !== initialAnswer.is_correct
        );
      });

      if (answersUpdated) {
        updatedFields.answers = questionToEdit.answers;
      }

      if (Object.keys(updatedFields).length > 0) {
        await modifyQuestion(questionToEdit.id, updatedFields, userInfo.token);
        toast.success("Question updated successfully!");
      } else {
        toast.info("No changes detected.");
      }
      setShowEditQuestionModal(false);
      await fetchUpdatedAssessment(selectedAssessment.id); // Refresh the assessment data
      setShowViewAssessmentModal(true); // Reopen the view assessment modal
    } catch (error) {
      toast.error("Failed to update question");
    }
  };


  // Handle delete question
  const handleDeleteQuestion = async () => {
    try {
      
      await deleteQuestion(questionToEdit.id, userInfo.token);
      toast.success("Question deleted successfully!");
      setShowDeleteQuestionModal(false);
      setShowViewAssessmentModal(false);
      await fetchUpdatedAssessment(selectedAssessment.id);
      setShowViewAssessmentModal(true); // Reopen the view assessment modal
    } catch (error) {
      toast.error("Failed to delete question");
    }
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Course"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            {userInfo?.role === "superadmin" ? (
              <AdminSideBar />
            ) : (
              <InstructorSideBar />
            )}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{courseDetails.title}</h3>
                    <p>Manage your course and lesson assessments</p>
                  </div>
                  {/* <div className="course-title-container p-3">
                    <h4>{courseDetails.title}</h4>
                  </div> */}

                  <div className="checkout-form pb-0">
                    <button
                      className="btn btn-primary"
                      onClick={() => openAddAssessmentModal()}
                    >
                      Add Assessment
                    </button>
                    <div className="tab-content mt-3">
                      <table className="table">
                        <thead style={{ backgroundColor: "#f0f0f0" }}>
                          <tr>
                            <th>Assessment</th>
                            <th>Actions</th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseDetails.sections?.map((section) =>
                            section.lessons.map((lesson) => {
                              const lessonAssessments = assessments.filter(
                                (assessment) => assessment.lesson === lesson.id
                              );
                              return lessonAssessments.map((assessment) => (
                                <tr key={assessment.id}>
                                  <td>
                                    <p className="mb-0">{assessment.title}</p>
                                    <small className="text-muted">
                                      {lesson.title}
                                    </small>
                                  </td>
                                  <td>
                                    {/* <button
                    className="btn btn-secondary"
                    onClick={() => {
                      setSelectedAssessment(assessment);
                      setShowQuestionModal(true);
                    }}
                  >
                    Add Question
                  </button> */}
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center tab-title"
                                      onClick={() => {
                                        setSelectedAssessment(assessment);
                                        openAddQuestionModal()
                                      }}
                                    >
                                      <i className="bx bx-plus me-2"></i>Add
                                      Question
                                    </Link>
                                    {/* <button
                    className="btn btn-info ms-2"
                    onClick={() => viewAssessment(assessment)}
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #000000",
                      color: "#000000",
                    }}
                  >
                    View Assessment
                  </button> */}
                                  </td>
                                  <td>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center tab-title"
                                      onClick={() => viewAssessment(assessment)}
                                    >
                                      <i
                                        class="fa fa-eye me-2"
                                        aria-hidden="true"
                                      ></i>
                                      View
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center tab-title"
                                      onClick={() => {
                                        setSelectedAssessment(assessment);
                                        setShowEditModal(true);
                                      }}
                                    >
                                      <i
                                        class="bx bx-edit me-2"
                                        aria-hidden="true"
                                      ></i>
                                      Edit
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center tab-title"
                                      onClick={() => {
                                        setSelectedAssessment(assessment);
                                        setShowDeleteModal(true);
                                      }}
                                    >
                                      <i
                                        class="bx bx-trash me-2"
                                        aria-hidden="true"
                                      ></i>
                                      Delete
                                    </Link>
                                  </td>
                                </tr>
                              ));
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Adding Assessment */}
      <div
        className={`modal ${showAssessmentModal ? "show" : ""}`}
        style={{ display: showAssessmentModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create Assessment</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowAssessmentModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <label htmlFor="assessment-title">Title</label>
              <input
                id="assessment-title"
                type="text"
                className="form-control mb-2"
                placeholder="Title"
                value={newAssessment.title}
                onChange={(e) =>
                  setNewAssessment({ ...newAssessment, title: e.target.value })
                }
              />

              <label htmlFor="assessment-description">Description</label>
              <textarea
                id="assessment-description"
                className="form-control mb-2"
                placeholder="Description"
                value={newAssessment.description}
                onChange={(e) =>
                  setNewAssessment({
                    ...newAssessment,
                    description: e.target.value,
                  })
                }
              ></textarea>

              <label htmlFor="pass-mark">Pass Mark</label>
              <input
                id="pass-mark"
                type="number"
                className="form-control mb-2"
                placeholder="Pass Mark"
                value={newAssessment.pass_mark}
                onChange={(e) =>
                  setNewAssessment({
                    ...newAssessment,
                    pass_mark: e.target.value,
                  })
                }
              />

              <label htmlFor="section-select">Select Section</label>
              <select
                id="section-select"
                className="form-control mb-2"
                onChange={(e) => setSelectedSection(e.target.value)}
              >
                <option>Select Section</option>
                {courseDetails.sections?.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.title}
                  </option>
                ))}
              </select>

              <label htmlFor="lesson-select">Select Lesson</label>
              <select
                id="lesson-select"
                className="form-control mb-2"
                value={selectedLesson || ""}
                onChange={(e) => setSelectedLesson(e.target.value)}
              >
                <option>Select Lesson</option>
                {filteredLessons.map((lesson) => (
                  <option key={lesson.id} value={lesson.id}>
                    {lesson.title}
                  </option>
                ))}
              </select>

              <button
                className="btn btn-primary"
                onClick={handleCreateAssessment}
              >
                Create Assessment
              </button>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => setShowAssessmentModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Adding Question */}
      <div
        className={`modal ${showQuestionModal ? "show" : ""}`}
        style={{ display: showQuestionModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Question</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowQuestionModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <label htmlFor="question-text">Question Text</label>
              <input
                id="question-text"
                type="text"
                className="form-control mb-2"
                placeholder="Question Text"
                value={questionData.question_text}
                onChange={(e) =>
                  setQuestionData({
                    ...questionData,
                    question_text: e.target.value,
                  })
                }
              />

              <label htmlFor="question-type">Question Type</label>
              <select
                id="question-type"
                className="form-control mb-2"
                value={questionData.question_type}
                onChange={(e) =>
                  setQuestionData({
                    ...questionData,
                    question_type: e.target.value,
                  })
                }
              >
                <option value="single_choice">Single Choice</option>
                <option value="multiple_choice">Multiple Choice</option>
                <option value="short_text">Short Text</option>
                <option value="essay">Essay</option>
              </select>

              <label htmlFor="question-marks">Marks</label>
              <input
                id="question-marks"
                type="number"
                className="form-control mb-2"
                placeholder="Marks"
                value={questionData.marks}
                onChange={(e) =>
                  setQuestionData({
                    ...questionData,
                    marks: parseInt(e.target.value, 10),
                  })
                }
              />

              {/* If Single Choice or Multiple Choice, allow adding answers */}
              {(questionData.question_type === "single_choice" ||
                questionData.question_type === "multiple_choice") && (
                <div className="multiple-choice-answers">
                  <button
                    className="btn btn-success mb-2"
                    onClick={addAnswer}
                    style={{
                      backgroundColor: "#000000",
                      border: "1px solid #000000",
                      color: "#ffffff",
                    }}
                  >
                    Add Answer
                  </button>
                  {questionData.answers.map((answer, index) => (
                    <div key={index} className="answer-group mb-2">
                      <label htmlFor={`answer-text-${index}`}>
                        Answer Text
                      </label>
                      <input
                        id={`answer-text-${index}`}
                        type="text"
                        className="form-control mb-1"
                        placeholder="Answer Text"
                        value={answer.answer_text}
                        onChange={(e) =>
                          handleAnswerChange(
                            index,
                            "answer_text",
                            e.target.value
                          )
                        }
                      />
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={answer.is_correct}
                          onChange={(e) =>
                            handleAnswerChange(
                              index,
                              "is_correct",
                              e.target.checked
                            )
                          }
                        />
                        <label className="form-check-label">
                          Correct Answer
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <button className="btn btn-primary" onClick={handleAddQuestion}>
                Add Question
              </button>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => setShowQuestionModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* View Assessment Modal */}
      {selectedAssessment && (
        <div
          className={`modal ${showViewAssessmentModal ? "show" : ""}`}
          style={{ display: showViewAssessmentModal ? "block" : "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Assessment: {selectedAssessment.title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowViewAssessmentModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                {selectedAssessment.questions.map((question, index) => (
                  <div key={index} className="mb-3">
                    <h6>
                      {index + 1}. {question.question_text}
                    </h6>
                    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                      {question.answers.map((answer, idx) => (
                        <li
                          key={idx}
                          className={answer.is_correct ? "fw-bold" : ""}
                        >
                          {String.fromCharCode(65 + idx)}. {answer.answer_text}
                        </li>
                      ))}
                    </ul>
                    <div className="question-actions">
                      <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={() => openEditQuestionModal(question)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setQuestionToEdit(question); // Set the question to delete
                          setShowDeleteQuestionModal(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowViewAssessmentModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Assessment Modal */}
      <div
        className={`modal ${showEditModal ? "show" : ""}`}
        style={{ display: showEditModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Assessment</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowEditModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <label htmlFor="edit-title">Title</label>
              <input
                id="edit-title"
                type="text"
                className="form-control mb-2"
                placeholder="Title"
                value={selectedAssessment?.title || ""}
                onChange={(e) =>
                  setSelectedAssessment({
                    ...selectedAssessment,
                    title: e.target.value,
                    initialTitle: selectedAssessment?.title,
                  })
                }
              />

              <label htmlFor="edit-description">Description</label>
              <textarea
                id="edit-description"
                className="form-control mb-2"
                placeholder="Description"
                value={selectedAssessment?.description || ""}
                onChange={(e) =>
                  setSelectedAssessment({
                    ...selectedAssessment,
                    description: e.target.value,
                    initialDescription: selectedAssessment?.description,
                  })
                }
              ></textarea>

              <label htmlFor="edit-pass-mark">Pass Mark</label>
              <input
                id="edit-pass-mark"
                type="number"
                className="form-control mb-2"
                placeholder="Pass Mark"
                value={selectedAssessment?.pass_mark || 0}
                onChange={(e) =>
                  setSelectedAssessment({
                    ...selectedAssessment,
                    pass_mark: e.target.value,
                    initialPassMark: selectedAssessment?.pass_mark,
                  })
                }
              />

              <label htmlFor="edit-section-select">Select Section</label>
              <select
                id="edit-section-select"
                className="form-control mb-2"
                value={selectedSection || ""}
                onChange={(e) => {
                  setSelectedSection(e.target.value);
                  const selectedSec = courseDetails.sections?.find(
                    (section) => section.id === parseInt(e.target.value, 10)
                  );
                  setFilteredLessons(selectedSec ? selectedSec.lessons : []);
                }}
              >
                <option>Select Section</option>
                {courseDetails.sections?.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.title}
                  </option>
                ))}
              </select>

              <label htmlFor="edit-lesson-select">Select Lesson</label>
              <select
                id="edit-lesson-select"
                className="form-control mb-2"
                value={selectedLesson || ""}
                onChange={(e) => setSelectedLesson(e.target.value)}
              >
                <option>Select Lesson</option>
                {filteredLessons.map((lesson) => (
                  <option key={lesson.id} value={lesson.id}>
                    {lesson.title}
                  </option>
                ))}
              </select>

              <button
                className="btn btn-primary"
                onClick={handleEditAssessment}
              >
                Save Changes
              </button>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => setShowEditModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm Delete Modal */}
      <div
        className={`modal ${showDeleteModal ? "show" : ""}`}
        style={{ display: showDeleteModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Assessment</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowDeleteModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this assessment?</p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={handleDeleteAssessment}
              >
                Confirm Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Question Modal with Answers */}
      <div
        className={`modal ${showEditQuestionModal ? "show" : ""}`}
        style={{ display: showEditQuestionModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Question</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowEditQuestionModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Question Text"
                value={questionToEdit?.question_text || ""}
                onChange={(e) =>
                  setQuestionToEdit({
                    ...questionToEdit,
                    question_text: e.target.value,
                  })
                }
              />
              <input
                type="number"
                className="form-control mb-2"
                placeholder="Marks"
                value={questionToEdit?.marks || 0}
                onChange={(e) =>
                  setQuestionToEdit({
                    ...questionToEdit,
                    marks: e.target.value,
                  })
                }
              />
              <h6 className="mt-3">Answers</h6>
              {questionToEdit?.answers.map((answer, index) => (
                <div key={index} className="answer-edit mb-2">
                  <input
                    type="text"
                    className="form-control mb-1"
                    placeholder="Answer Text"
                    value={answer.answer_text}
                    onChange={(e) =>
                      handleAnswerChange(
                        index,
                        "answer_text",
                        e.target.value,
                        "edit"
                      )
                    }
                  />
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={answer.is_correct}
                      onChange={(e) =>
                        handleAnswerChange(
                          index,
                          "is_correct",
                          e.target.checked,
                          "edit"
                        )
                      }
                    />
                    <label className="form-check-label">Correct Answer</label>
                  </div>
                </div>
              ))}
              <button className="btn btn-primary" onClick={handleEditQuestion}>
                Save Changes
              </button>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => setShowEditQuestionModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm Delete Question Modal */}
      <div
        className={`modal ${showDeleteQuestionModal ? "show" : ""}`}
        style={{ display: showDeleteQuestionModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Question</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowDeleteQuestionModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this question?</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger" onClick={handleDeleteQuestion}>
                Confirm Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowDeleteQuestionModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddAssessments;
