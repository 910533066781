// src/api/index.js
import axios from "axios";
import "./interceptor";

const API_URL = process.env.REACT_APP_API_URL;

// Register a new user
export const register = async (
  first_name,
  last_name,
  email,
  password,
  user_category = null
) => {
  try {
    const response = await axios.post(`${API_URL}users/register/`, {
      first_name,
      last_name,
      email,
      password,
      user_category,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Login a user
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}users/login/`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Google Register
export const googleRegister = async (googleToken) => {
  try {
    const response = await axios.post(`${API_URL}users/google-register/`, {
      token: googleToken,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unexpected error occurred" };
  }
};

// Google Login
export const googleLogin = async (googleToken) => {
  try {
    const response = await axios.post(`${API_URL}users/google-login/`, {
      token: googleToken,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unexpected error occurred" };
  }
};

// Refresh Token Function
export const refreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${API_URL}users/token/refresh/`, {
      refresh: refreshToken, // Pass refresh token in request body
    });
    return response; // Return the response, which includes the new access token
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to refresh token" };
  }
};

// List All Users
export const listAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}users/all/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Assumes access token is stored in localStorage
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "You do not have permission to view all users" };
  }
};

// Logout a user
export const logout = (refreshToken) => {
  return axios.post(`${API_URL}users/logout/`, { refresh: refreshToken });
};

// Password reset request
export const passwordReset = (email) => {
  return axios.post(`${API_URL}users/password-reset/`, { email });
};

// Confirm password reset
export const passwordResetConfirm = (uidb64, token, newPassword) => {
  return axios.post(
    `${API_URL}users/password-reset-confirm/${uidb64}/${token}/`,
    { new_password: newPassword }
  );
};

// Get user profile
export const getUserProfile = (token) => {
  return axios.get(`${API_URL}users/profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changePassword = async (oldPassword, newPassword, token) => {
  try {
    const response = await axios.post(
      `${API_URL}users/profile/change-password/`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Update user profile
export const updateUserProfile = async (profileData, token) => {
  try {
    const formData = new FormData();

    Object.keys(profileData).forEach((key) => {
      if (profileData[key]) {
        formData.append(key, profileData[key]);
      }
    });

    const response = await axios.patch(
      `${API_URL}users/profile/update/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Deactivate user

export const deactivateUser = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}users/deactivate/${userId}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Reactivate User

export const reactivateUser = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}users/reactivate/${userId}/`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Send support request
export const sendSupportRequest = async (
  first_name,
  email,
  subject,
  description
) => {
  try {
    const response = await axios.post(`${API_URL}users/contact-form/`, {
      first_name,
      email,
      subject,
      description,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Failed to send support request. Please try again." };
  }
};

// Create a new category
export const createCategory = async (categoryData) => {
  try {
    const response = await axios.post(
      `${API_URL}courses/categories/`,
      categoryData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Get the list of categories
export const getCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}courses/categories/`);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Retrieve a specific category by ID
export const getCategoryById = async (categoryId) => {
  try {
    const response = await axios.get(
      `${API_URL}courses/categories/${categoryId}/`
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Category not found." };
  }
};

// Update an existing category by ID
export const updateCategory = async (categoryId, categoryData) => {
  try {
    const response = await axios.put(
      `${API_URL}courses/categories/${categoryId}/`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to update category." };
  }
};

// Delete a category by ID
export const deleteCategory = async (categoryId) => {
  try {
    const response = await axios.delete(
      `${API_URL}courses/categories/${categoryId}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.status === 204
      ? { message: "Category deactivated successfully." }
      : response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to delete category." };
  }
};

// Create a new course
export const createCourse = async (courseData) => {
  try {
    const response = await axios.post(`${API_URL}courses/create/`, courseData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Get all Courses

export const getCourses = async () => {
  const response = await axios.get(`${API_URL}courses/all/`);
  return response;
};

// Get Featured Courses
export const getFeaturedCourses = (page = 1, token) => {
  return axios
    .get(`${API_URL}courses/featured/?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Add to Featured Courses (Admins only)
export const addToFeaturedCourses = (course_id, token) => {
  return axios
    .post(
      `${API_URL}courses/featured/add/`,
      { course_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Remove from Featured Courses - Admin
export const removeFromFeaturedCourses = (course_id, token) => {
  return axios
    .delete(`${API_URL}courses/featured/remove/`, {
      data: { course_id },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Single Course Information
export const getCourseDetails = (course_id) => {
  return axios
    .get(`${API_URL}courses/${course_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update Course
export const updateCourse = (course_id, formData, token) => {
  return axios
    .put(`${API_URL}courses/${course_id}/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Delete Course
export const deleteCourse = (course_id, token) => {
  return axios
    .delete(`${API_URL}courses/${course_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Reactivate Course
export const reactivateCourse = (course_id, token) => {
  return axios
    .patch(`${API_URL}courses/${course_id}/reactivate/`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Assign Instructor to Course
export const assignInstructorToCourse = (course_id, instructor_id, token) => {
  return axios
    .post(
      `${API_URL}instructors/${course_id}/assign-instructor/`,
      { instructor_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Remove Instructor from Course
export const removeInstructorFromCourse = (course_id, instructor_id, token) => {
  return axios
    .delete(`${API_URL}instructors/${course_id}/remove-instructor/`, {
      data: { instructor_id },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Course Instructors
export const getCourseInstructors = (course_id, token) => {
  return axios
    .get(`${API_URL}instructors/${course_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Change Main Instructor
export const changeMainInstructor = (course_id, new_instructor_id, token) => {
  return axios
    .post(
      `${API_URL}${course_id}/change-main-instructor/`,
      { new_instructor_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// List Sections for a Course
export const listSectionsForCourse = (course_id, params, token) => {
  return axios
    .get(`${API_URL}courses/${course_id}/sections/`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Create Section
export const createSection = (course_id, sectionData, token) => {
  return axios
    .post(`${API_URL}courses/${course_id}/sections/create/`, sectionData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Single Section Information
export const getSectionDetails = (course_id, section_id, token) => {
  return axios
    .get(`${API_URL}${course_id}/sections/${section_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update Section
export const updateSection = (course_id, section_id, sectionData, token) => {
  return axios
    .put(`${API_URL}${course_id}/sections/${section_id}/`, sectionData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Delete Section
export const deleteSection = (course_id, section_id, token) => {
  return axios
    .delete(`${API_URL}${course_id}/sections/${section_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Reactivate Section
export const reactivateSection = (course_id, section_id, token) => {
  return axios
    .patch(`${API_URL}${course_id}/sections/${section_id}/reactivate/`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// list lessons
export const listLessonsForSection = (course_id, section_id, token) => {
  return axios
    .get(`${API_URL}courses/${course_id}/sections/${section_id}/lessons/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

//create lesson
export const createLesson = (course_id, section_id, lessonData, token) => {
  const formData = new FormData();
  formData.append("title", lessonData.title);
  formData.append("content_type", lessonData.content_type);

  if (lessonData.content_file) {
    formData.append("content_file", lessonData.content_file);
  }

  if (lessonData.content) {
    formData.append("content", lessonData.content);
  }

  //formData.append("order", lessonData.order);

  return axios
    .post(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/create/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get a Single Lesson
export const getLessonDetails = (course_id, section_id, lesson_id, token) => {
  return axios
    .get(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update a Lesson

export const updateLesson = (
  course_id,
  section_id,
  lesson_id,
  lessonData,
  token
) => {
  const formData = new FormData();
  formData.append("title", lessonData.title);
  formData.append("content_type", lessonData.content_type);

  if (lessonData.content_file) {
    formData.append("content_file", lessonData.content_file);
  }

  if (lessonData.content) {
    formData.append("content", lessonData.content);
  }

  //formData.append("order", lessonData.order);

  return axios
    .put(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// delete a lesson
export const deleteLesson = (course_id, section_id, lesson_id, token) => {
  return axios
    .delete(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Course Progress
export const getCourseProgress = async (course_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}courses/${course_id}/progress/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Update Lesson Progress
export const updateLessonProgress = async (course_id, lessonData, token) => {
  try {
    const response = await axios.post(
      `${API_URL}courses/${course_id}/progress/`,
      lessonData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// List Enrollments
export const listEnrollments = (params, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve Enrollment Details
export const getEnrollmentDetails = (enrollment_id, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/${enrollment_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Enroll a User
export const enrollInCourse = (course_id, token) => {
  return axios
    .post(
      `${API_URL}courses/enrollments/enroll/`,
      { course_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Unenroll a User from Course
export const unenrollFromCourse = (enrollment_id, token) => {
  return axios
    .delete(`${API_URL}courses/enrollments/${enrollment_id}/unenroll/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Check Enrollment Status
export const checkEnrollmentStatus = (course_id, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/status/${course_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve User Enrollments
export const getUserEnrollments = (user_id, params, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/enrollment/user/${user_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve Course Enrollments
export const getCourseEnrollments = (course_id, token) => {
  // const url = `${API_URL}courses/enrollments/${course_id}/`;

  return axios
    .get(`${API_URL}courses/enrollments/course/${course_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};


// ASSESSMENTS MODULE

// Create a new assessment
export const createAssessment = async (assessmentData, token) => {
  try {
    const response = await axios.post(
      `${API_URL}assessments/create/`,
      assessmentData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to create assessment" };
  }
};

// Edit an assessment
export const editAssessment = async (assessment_id, assessmentData, token) => {
  try {
    const response = await axios.put(
      `${API_URL}assessments/edit/${assessment_id}/`,
      assessmentData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to edit assessment" };
  }
};

// Get all assessments
export const getAssessments = async (params, token) => {
  try {
    const response = await axios.get(`${API_URL}assessments/get/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to fetch assessments" };
  }
};

// Deactivate assessment
export const deactivateAssessment = async (assessment_id, token) => {
  try {
    const response = await axios.delete(
      `${API_URL}assessments/deactivate/${assessment_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to deactivate assessment" };
  }
};

// Add question to an assessment
export const addQuestionToAssessment = async (
  assessment_id,
  questionData,
  token
) => {
  try {
    const response = await axios.post(
      `${API_URL}assessments/add-question/${assessment_id}/`,
      questionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to add question" };
  }
};

// Modify a question
export const modifyQuestion = async (question_id, questionData, token) => {
  try {
    const response = await axios.put(
      `${API_URL}assessments/modify-question/${question_id}/`,
      questionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to modify question" };
  }
};

// Delete a question
export const deleteQuestion = async (question_id, token) => {
  try {
    const response = await axios.delete(
      `${API_URL}assessments/delete-question/${question_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to delete question" };
  }
};

// Submit an assessment
export const submitAssessment = async (assessment_id, answers, token) => {
  try {
    const response = await axios.post(
      `${API_URL}assessments/submit/${assessment_id}/`,
      { answers },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to submit assessment" };
  }
};

// Grade a submission
export const gradeSubmission = async (submission_id, grades, token) => {
  try {
    const response = await axios.post(
      `${API_URL}assessments/grade-submission/`,
      { submission_id, grades },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to grade submission" };
  }
};

// Edit grading of a submission
export const editGrade = async (submission_id, grades, token) => {
  try {
    const response = await axios.put(
      `${API_URL}assessments/edit-grade/${submission_id}/`,
      { submission_id, grades },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to edit grade" };
  }
};

// Get performance statistics for an assessment
export const getAssessmentPerformance = async (assessment_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/performance/${assessment_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve performance" };
  }
};

// Get learner performance statistics
export const getLearnerPerformance = async (user_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/learner-performance/${user_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve learner performance" };
  }
};

// Get learner submissions for an assessment
export const getLearnerSubmissions = async (user_id, assessment_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/learner-submissions/${user_id}/${assessment_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve learner submissions" };
  }
};

// Get the completion status of mandatory assessments for a learner
export const getMandatoryCompletionStatus = async (user_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/mandatory-completion/${user_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve mandatory completion status" };
  }
};

// Get average score of mandatory assessments for a learner
export const getMandatoryAverageScore = async (user_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/mandatory-average/${user_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve mandatory average score" };
  }
};

// Get assessments for a specific lesson
export const getLessonAssessments = async (lesson_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/lesson-assessments/${lesson_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve lesson assessments" };
  }
};

// Get assessments for a specific course
export const getCourseAssessments = async (course_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/course-assessments/${course_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve course assessments" };
  }
};

// Get User Submissions
export const getUserSubmissions = async (params, token) => {
  try {
    const response = await axios.get(
      `${API_URL}assessments/user-submissions/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve user submissions" };
  }
};

// USER CATEGORY MODULE

// Fetch all user categories
export const fetchUserCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}user-categories/`);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to fetch user categories." };
  }
};


// Certificates Module

// List Certificates
export const listCertificates = async (token) => {
  try {
    const response = await axios.get(`${API_URL}certificates/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve certificates." };
  }
};

// Create Certificate (Superadmin only)
export const createCertificate = async (course_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${API_URL}certificates/`,
      { course_id, user_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to create certificate." };
  }
};

// Retrieve Certificate
export const getCertificate = async (certificate_id, token) => {
  try {
    const response = await axios.get(`${API_URL}certificates/${certificate_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Certificate not found." };
  }
};

// Update Certificate (Superadmin only)
export const updateCertificate = async (certificate_id, certificateData, token) => {
  try {
    const response = await axios.put(
      `${API_URL}certificates/${certificate_id}/`,
      certificateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to update certificate." };
  }
};

// Delete Certificate (Superadmin only)
export const deleteCertificate = async (certificate_id, token) => {
  try {
    const response = await axios.delete(`${API_URL}certificates/${certificate_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.status === 204
      ? { message: "Certificate deleted successfully." }
      : response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to delete certificate." };
  }
};

// Download Certificate
export const downloadCertificate = async (certificate_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}certificates/${certificate_id}/download/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob'
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to download certificate." };
  }
};

// Share Certificate
export const shareCertificate = async (certificate_id, token) => {
  try {
    const response = await axios.post(
      `${API_URL}certificates/${certificate_id}/share/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to share certificate." };
  }
};

// Verify Certificate (Public access)
export const verifyCertificate = async (certificate_number) => {
  try {
    const response = await axios.get(
      `${API_URL}certificates/verify/`,
      {
        params: { certificate_number }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Certificate not found." };
  }
};

// Certificate Templates Management (Admin only)

// List Certificate Templates
export const listCertificateTemplates = async (token) => {
  try {
    const response = await axios.get(`${API_URL}certificates/certificate-templates/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve certificate templates." };
  }
};

// Create Certificate Template
export const createCertificateTemplate = async (templateData, token) => {
  try {
    const response = await axios.post(
      `${API_URL}certificates/certificate-templates/`,
      templateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to create certificate template." };
  }
};

// Retrieve Certificate Template
export const getCertificateTemplate = async (template_id, token) => {
  try {
    const response = await axios.get(
      `${API_URL}certificates/certificate-templates/${template_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Certificate template not found." };
  }
};

// Update Certificate Template
export const updateCertificateTemplate = async (template_id, templateData, token) => {
  try {
    const response = await axios.put(
      `${API_URL}certificates/certificate-templates/${template_id}/`,
      templateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to update certificate template." };
  }
};

// Delete Certificate Template
export const deleteCertificateTemplate = async (template_id, token) => {
  try {
    const response = await axios.delete(
      `${API_URL}certificates/certificate-templates/${template_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.status === 204
      ? { message: "Certificate template deleted successfully." }
      : response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to delete certificate template." };
  }
};

// Bulk Generate Certificates (Admin only)
export const bulkGenerateCertificates = async (course_id, token) => {
  try {
    const response = await axios.post(
      `${API_URL}certificates/bulk-generate/`,
      { course_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to initiate bulk certificate generation." };
  }
};

// Certificate Analytics (Admin only)
export const getCertificateAnalytics = async (token) => {
  try {
    const response = await axios.get(`${API_URL}certificates/analytics/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to retrieve certificate analytics." };
  }
};