import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import PageHeader from "../header";

const TermsCondition = () => {
  return (
    <>
      <div className="main-wrapper">
        <PageHeader />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Pages</li>
                      <li className="breadcrumb-item">
                        Terms &amp; Conditions
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner quick-links">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h1 className="mb-0">Terms &amp; Conditions
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <div className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="terms-content">
                            <div className="terms-text">
                                <h3>Terms &amp; Conditions for J'funze LMS Platform</h3>
                                <h4>1. Introduction</h4>
    <p>Welcome to J'funze, an online Learning Management System (LMS) owned and operated by Solidaridad. By accessing and using this platform, you agree to comply with the following terms and conditions. If you do not agree, please refrain from using the platform.</p>

    <h4>2. User Accounts</h4>
    <ul>
        <li>All users are required to create an account to access J'funze.</li>
        <li>Users must provide accurate and up-to-date information during registration.</li>
        <li>Each account is personal, non-transferable, and must be kept secure by the user. Users are responsible for any activities that occur under their account.</li>
    </ul>

    <h4>3. Platform Use</h4>
    <ul>
        <li>The platform is provided to facilitate learning and development within Solidaridad.</li>
        <li>All content is for educational purposes only. Unauthorized commercial use of the platform is prohibited.</li>
        <li>Users agree not to use the platform for any unlawful activities or to violate any applicable laws or regulations.</li>
    </ul>

    <h4>4. Content Ownership</h4>
    <ul>
        <li>All learning materials, resources, and content provided on J'funze are the property of Solidaridad or the respective content creators and are protected by intellectual property laws.</li>
        <li>Users may access, view, and download the content for personal and educational use only. Redistribution, modification, or commercial use of the content without permission is prohibited.</li>
    </ul>

    <h4>5. User-Generated Content</h4>
    <ul>
        <li>Users may have the ability to upload content (e.g., assignments, feedback) as part of their learning experience. Solidaridad reserves the right to review, modify, or remove any user-generated content that violates these terms.</li>
        <li>By submitting content, users grant Solidaridad a non-exclusive, royalty-free license to use, display, and distribute the content for educational purposes.</li>
    </ul>

    <h4>6. Privacy</h4>
    <p>Solidaridad is committed to protecting user privacy. Personal data collected during registration and use of the platform will be processed in accordance with applicable international data protection laws, including the General Data Protection Regulation (GDPR) for users in the European Union, and other relevant privacy regulations.</p>
    <p>Users should be aware that certain information, such as course progress and completion, may be visible to administrators and instructors.</p>

    <h4>7. Termination of Access</h4>
    <p>Solidaridad reserves the right to suspend or terminate a user's access to J'funze for violating these terms, engaging in unlawful activities, or any misuse of the platform.</p>

    <h4>8. Platform Availability</h4>
    <p>While Solidaridad strives to ensure J'funze operates smoothly and efficiently, we do not guarantee uninterrupted access to the platform. Solidaridad will not be liable for any downtime, interruptions, or data loss that may occur.</p>

    <h4>9. Updates and Modifications</h4>
    <p>Solidaridad reserves the right to update or modify these terms at any time. Users will be notified of any significant changes. Continued use of the platform following any updates constitutes acceptance of the revised terms.</p>

    <h4>10. Governing Law</h4>
    <p>These terms and conditions are governed by international intellectual property laws, GDPR (for EU users), and other applicable global data protection standards. In case of disputes, these will be handled under the legal frameworks applicable to the region of the user or the entity.</p>

    <h4>11. Contact Information</h4>
    <p>For any inquiries or issues related to the platform, please contact the J'funze support team at <a href="mailto:jfunze@solidaridadnetwork.org">jfunze@solidaridadnetwork.org</a>.</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default TermsCondition;
