import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import PageHeader from "../header";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="main-wrapper">
        <PageHeader />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Pages</li>
                      <li className="breadcrumb-item">Privacy Policy</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner quick-links">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-content">
                  <div className="terms-text">
                    <h3>Privacy Policy for J'funze LMS Platform</h3>
                    <h4>1. Introduction</h4>
                    <p>
                      This Privacy Policy outlines how J'funze, the Learning
                      Management System (LMS) owned by Solidaridad, collects,
                      uses, discloses, and protects personal data of its users.
                      By using the platform, you agree to the practices
                      described in this policy.
                    </p>

                    <h4>2. Data Collection</h4>
                    <p>
                      We collect personal data to ensure smooth operation of the
                      LMS and to provide you with the best learning experience.
                      The types of data collected include:
                    </p>
                    <ul>
                      <li>
                        <strong>Personal Information:</strong> Full name, email
                        address, job title, department, location, and other
                        contact details during account registration.
                      </li>
                      <li>
                        <strong>Course Data:</strong> Progress, completion
                        status, feedback, and performance on assessments.
                      </li>
                      <li>
                        <strong>Technical Data:</strong> IP addresses, browser
                        type, device information, and usage logs for system
                        administration and security purposes.
                      </li>
                      <li>
                        <strong>Communications:</strong> Any correspondence
                        through the platform, including discussions and
                        inquiries.
                      </li>
                    </ul>

                    <h4>3. Use of Data</h4>
                    <p>
                      The data collected is used for the following purposes:
                    </p>
                    <ul>
                      <li>
                        <strong>Learning and Development:</strong> To track
                        progress, manage user learning experiences, and provide
                        support.
                      </li>
                      <li>
                        <strong>Administration:</strong> For system performance
                        monitoring, maintenance, and security.
                      </li>
                      <li>
                        <strong>Improvement:</strong> To analyze how users
                        engage with the platform and make necessary updates or
                        improvements.
                      </li>
                      <li>
                        <strong>Communications:</strong> To respond to your
                        inquiries, send platform updates, and provide
                        course-related information.
                      </li>
                      <li>
                        <strong>Compliance:</strong> To ensure compliance with
                        legal obligations, especially related to data protection
                        regulations.
                      </li>
                    </ul>

                    <h4>4. Data Sharing and Disclosure</h4>
                    <p>
                      We do not sell or rent personal information to third
                      parties. However, we may share data:
                    </p>
                    <ul>
                      <li>
                        <strong>With Internal Teams:</strong> Authorized
                        personnel within Solidaridad, such as instructors and
                        administrators, may access user data to manage learning
                        and development.
                      </li>
                      <li>
                        <strong>With Service Providers:</strong> Third-party
                        service providers (e.g., cloud storage and platform
                        maintenance) may process data on our behalf under strict
                        confidentiality agreements.
                      </li>
                      <li>
                        <strong>Legal Requirements:</strong> We may disclose
                        data to comply with legal obligations or in response to
                        lawful requests by public authorities.
                      </li>
                    </ul>
                    <h4>5. Data Protection and Security</h4>
                    <p>
                      We are committed to ensuring the security of your personal
                      data. We implement technical and organizational measures,
                      such as encryption, secure servers, and access controls,
                      to safeguard against unauthorized access, alteration,
                      disclosure, or destruction of your data.
                    </p>

                    <h4>6. Data Retention</h4>
                    <p>
                      Personal data will be retained for as long as necessary to
                      fulfill the purposes for which it was collected, including
                      legal and reporting obligations. Users can request
                      deletion of their account and personal data at any time,
                      subject to legal requirements.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h4>7. User Rights.</h4>
                    <p>
                      Depending on your location, you may have certain rights
                      under data protection laws, such as the General Data
                      Protection Regulation (GDPR) for users in the EU:
                    </p>
                    <ul>
                      <li>
                        <strong>Access:</strong> Request a copy of your personal
                        data.
                      </li>
                      <li>
                        <strong>Correction:</strong> Correct inaccuracies in
                        your personal data.
                      </li>
                      <li>
                        <strong>Deletion:</strong> Request deletion of your
                        personal data (right to be forgotten).
                      </li>
                      <li>
                        <strong>Objection:</strong> Object to processing of your
                        data.
                      </li>
                      <li>
                        <strong>Portability:</strong> Request transfer of your
                        data to another service.
                      </li>
                    </ul>
                  </div>
                  <div className="terms-text">
                    <h4>8. International Data Transfers</h4>
                    <p>
                      As a global organization, Solidaridad may transfer
                      personal data across borders to provide its services. We
                      ensure that any such transfers comply with applicable
                      laws, such as GDPR, through appropriate safeguards (e.g.,
                      standard contractual clauses).wser, though disabling
                      cookies may affect functionality.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h4>9. Cookies</h4>
                    <p>
                      J'funze uses cookies and similar tracking technologies to
                      enhance user experience and analyze platform usage. Users
                      can control cookie settings through their browser, though
                      disabling cookies may affect functionality.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h4>10. Changes about terms</h4>
                    <p>
                      Solidaridad reserves the right to update this Privacy
                      Policy at any time. Users will be notified of any material
                      changes, and continued use of the platform following such
                      updates constitutes acceptance of the revised policy.
                    </p>
                    <h4>Contact Information</h4>
                    <p>
                      If you have any questions or concerns regarding this
                      Privacy Policy, please contact us at{" "}
                      <Link to="mailto:jfunze@solidaridadnetwork.org">jfunze@solidaridadnetwork.org</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
