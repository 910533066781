import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCategories, getCourseDetails, updateCourse } from "../../../../api";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import Footer from "../../../footer";
import CourseHeader from "../header";
import InstructorSidebar from "../../../instructor/sidebar";
import AdminSidebar from "../../../admin/sidebar";
import { AuthContext } from "../../../../context/AuthContext";

const EditCourse = () => {
  const { userInfo } = useContext(AuthContext);
  const { courseId } = useParams();
  const [course, setCourse] = useState({
    title: '',
    description: '',
    objectives: '',
    duration: '',
    outcomes: '',
    categories: [],
    banner: null,
    icon: null
  });
  const [initialCourse, setInitialCourse] = useState({});
  const [categories, setCategories] = useState([]);
  const [banner, setBanner] = useState(null);
  const [icon, setIcon] = useState(null);
  const navigate = useNavigate();

  // Fetch categories and course details on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        toast.error("Failed to fetch categories");
      }
    };

    const fetchCourseDetails = async () => {
      try {
        const courseDetails = await getCourseDetails(courseId);
        setCourse({
          title: courseDetails.title,
          description: courseDetails.description,
          objectives: courseDetails.objectives,
          duration: courseDetails.duration,
          outcomes: courseDetails.outcomes,
          categories: courseDetails.categories.map(category => category.id),
          banner: null,
          icon: null
        });
        setInitialCourse({
          title: courseDetails.title,
          description: courseDetails.description,
          objectives: courseDetails.objectives,
          duration: courseDetails.duration,
          outcomes: courseDetails.outcomes,
          reference_materials: courseDetails.reference_materials,
          categories: courseDetails.categories.map(category => category.id),
        });
      } catch (error) {
        toast.error("Failed to fetch course details");
      }
    };

    fetchCategories();
    fetchCourseDetails();
  }, [courseId]);

  const handleChange = (field, value) => {
    setCourse({ ...course, [field]: value });
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedCategories = selectedOptions.map(option => option.value);
    setCourse({ ...course, categories: selectedCategories });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "banner") setBanner(files[0]);
    if (name === "icon") setIcon(files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Add only modified fields to the formData
    if (course.title !== initialCourse.title) formData.append("title", course.title);
    if (course.description !== initialCourse.description) formData.append("description", course.description);
    if (course.objectives !== initialCourse.objectives) formData.append("objectives", course.objectives);
    if (course.duration !== initialCourse.duration) formData.append("duration", course.duration);
    if (course.outcomes !== initialCourse.outcomes) formData.append("outcomes", course.outcomes);
    if (course.reference_materials !== initialCourse.reference_materials) formData.append("reference_materials", course.reference_materials);

    // Append each category ID as a separate 'categories' field
    if (JSON.stringify(course.categories) !== JSON.stringify(initialCourse.categories)) {
      course.categories.forEach((id) => formData.append("categories", id));
    }

    // Append banner and icon if they are updated
    if (banner) formData.append("banner", banner);
    if (icon) formData.append("icon", icon);

    formData.append("is_course_active", true);
    formData.append("is_featured", true);

    try {
      await updateCourse(courseId, formData, userInfo.token);
      toast.success("Course updated successfully!");
      setTimeout(() => {
        navigate(`/admin/course/${courseId}/manage-curriculum`);
      }, 1000);
    } catch (error) {
      console.error("Error updating course:", error);
      toast.error("Failed to update course");
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader />
        <div className="page-content">
          <div className="container">
            <div className="row">
              {userInfo?.role === 'superadmin' ? <AdminSidebar /> : <InstructorSidebar />}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Edit Course</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="checkout-form settings-wrap">
                        <div className="row">
                          <div className="col-md-12">
                          <div className="input-block">
                          <label className="add-course-label">Course Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Course Title"
                            value={course.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            required
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Courses Category</label>
                          <Select
                            isMulti
                            options={categories.map(category => ({ label: category.title, value: category.id }))}
                            onChange={handleCategoryChange}
                            placeholder="Select Categories"
                            value={course.categories.map(id => ({ label: categories.find(cat => cat.id === id)?.title, value: id }))} // Set selected categories
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Description</label>
                          <textarea
                            className="form-control"
                            placeholder="Course description"
                            value={course.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            required
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Objectives</label>
                          <textarea
                            className="form-control"
                            placeholder="Course Objectives"
                            value={course.objectives}
                            onChange={(e) => handleChange('objectives', e.target.value)}
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Duration (Hours)</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Duration"
                            value={course.duration}
                            onChange={(e) => handleChange('duration', e.target.value)}
                            required
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Outcomes</label>
                          <textarea
                            className="form-control"
                            placeholder="Course Outcomes"
                            value={course.outcomes}
                            onChange={(e) => handleChange('outcomes', e.target.value)}
                          />
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Cover Image</label>
                          <div className="relative-form">
                            <span>{banner ? banner.name : "No File Selected"}</span>
                            <label className="relative-file-upload">
                              Upload File
                              <input
                                type="file"
                                name="banner"
                                onChange={handleFileChange}
                                accept="image/*"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="input-block">
                          <label className="add-course-label">Course Icon</label>
                          <div className="relative-form">
                            <span>{icon ? icon.name : "No File Selected"}</span>
                            <label className="relative-file-upload">
                              Upload File
                              <input
                                type="file"
                                name="icon"
                                onChange={handleFileChange}
                                accept="image/*"
                              />
                            </label>
                          </div>
                        </div>
                            <button className="btn btn-primary btn-add-course" type="submit">
                              Update Course
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};

export default EditCourse;
