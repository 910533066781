import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { useNavigate } from "react-router-dom";
import { User16 } from "../../imagepath";

import { AuthContext } from "../../../context/AuthContext.js";


// eslint-disable-next-line react/prop-types
export default function StudentSidebar() {
  const { userInfo } = useContext(AuthContext); 
  const location = useLocation();
  const navigate = useNavigate();
  const {logoutUser } = useContext(AuthContext);

  
  const handleLogoout = (e) => {
    e.preventDefault();
    logoutUser();
    navigate ('/login');
  }

  return (
    <div className="col-xl-3 col-lg-3 theiaStickySidebar">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="settings-widget dash-profile">
          <div className="settings-menu">
            <div className="profile-bg">
              <div className="profile-img" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
                <Link to="/student/student-profile">
                {/* <img
                src={userInfo?.profile_picture || User16} 
                alt="User"
              /> */}

<div
        style={{
          backgroundColor: '#D9D9C3',
          color: '#000',
          border: '5px solid #fff',
          borderRadius: '50%',
          width: '125px',
          height: '125px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '36px', // Adjust this to fit the size of the circle
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {userInfo?.first_name?.[0]}{userInfo?.last_name?.[0]}
      </div>
                </Link>
              </div>
            </div>
            <div className="profile-group">
              <div className="profile-name text-center">
                <h4>
                  <Link to="/student/student-profile">{userInfo?.first_name} {userInfo?.last_name}</Link>
                </h4>
                <p>{userInfo?.role === 'learner' ? 'Student' : userInfo?.role}</p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="settings-widget account-settings">
          <div className="settings-menu">
            {/* <h3>Dashboard</h3> */}
            <ul>
              <li className={`nav-item ${location.pathname === '/student/student-dashboard' ? 'active' : ''}`}>

                <Link to="/student/student-dashboard" className="nav-link">
                  <i className="bx bxs-tachometer" />
                  Dashboard
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-profile' ? 'active' : ''}`}>

                <Link to="/student/student-profile" className="nav-link">
                  <i className="bx bxs-user" />
                  My Profile
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-courses' ? 'active' : ''}`}>
                <Link
                  to="/student/student-courses"
                  className="nav-link"
                >
                  <i className="bx bxs-graduation" />
                  Enrolled Courses
                </Link>
              </li>
              {/* <li className={`nav-item ${location.pathname === '/student/student-wishlist' || location.pathname === '/student/student-quiz-details' ? 'active' : ''}`}>

                <Link to="/student/student-wishlist" className="nav-link">
                  <i className="bx bxs-heart" />
                  Wishlist
                </Link>
              </li> */}
              {/* <li className={`nav-item ${location.pathname === '/student/student-reviews' ? 'active' : ''}`}>

                <Link to="/student/student-reviews" className="nav-link">
                  <i className="bx bxs-star" />
                  Reviews
                </Link>
              </li> */}
              <li className={`nav-item ${location.pathname === '/student/student-quiz' ? 'active' : ''}`}>

                <Link to="/student/student-assessment" className="nav-link">
                  <i className="bx bxs-shapes" />
                  Assessments
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-certificates' ? 'active' : ''}`}>
                <Link to="/student/student-certificates" className="nav-link">
                  <i className="bx bxs-cart" />
                  Certificates
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/student/student-qa' ? 'active' : ''}`}>

                {/* <Link to="/student/student-qa" className="nav-link"> */}
                <Link to="/life-at-solidaridad" className="nav-link">
                  <i className="bx bxs-bookmark-alt" />
                  Life at Solidaridad
                </Link>
              </li>
              {/* <li className={`nav-item ${location.pathname === '/student/student-referral' ? 'active' : ''}`}>

                <Link to="/student/student-referral" className="nav-link">
                  <i className="bx bxs-user-plus" />
                  Referrals
                </Link>
              </li> */}
              {/* <li className={`nav-item ${location.pathname === '/student/student-messages' ? 'active' : ''}`}>
                <Link to="/student/student-messages" className="nav-link">
                  <i className="bx bxs-chat" />
                  Messages
                </Link>
              </li>

              <li className={`nav-item ${location.pathname === '/student/student-ticket' ? 'active' : ''}`}>

                <Link to="/student/student-ticket" className="nav-link">
                  <i className="bx bxs-coupon" />
                  Support Tickets
                </Link>
              </li> */}
            </ul>
            <h3>Account Settings</h3>
            <ul>
              <li className={`nav-item ${location.pathname === '/student/student-setting' || location.pathname === '/student/student-change-password' || location.pathname === '/student/student-social-profile' || location.pathname === '/student/student-linked-accounts' || location.pathname === '/student/student-notification' ? 'active' : ''}`}>
                <Link to="/student/student-setting" className="nav-link ">
                  <i className="bx bxs-cog" />
                  Settings
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/logout" onClick={handleLogoout} className="nav-link">
                  <i className="bx bxs-log-out" />
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
