import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { InstructorHeader } from "../header";
import InstructorSidebar from "../sidebar";
import AdminSidebar from "../../admin/sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../context/AuthContext";
import { getUserSubmissions, getCourses } from "../../../api"; // Import API methods

const InstructorQuizAttempts = () => {
  const { userInfo, token } = useContext(AuthContext);
  const mobileSidebar = useSelector((state) => state.sidebarSlice.expandMenu);
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [submissions, setSubmissions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDateChange1 = (date) => setSelectedDate1(date);

  // Fetch submissions with optional course filter
  const fetchSubmissions = async (courseId = null) => {
    setLoading(true);
    setError(null);
    try {
      const params = courseId ? { course_id: courseId } : {};
      const data = await getUserSubmissions(params, token);
      setSubmissions(data.results);
    } catch (error) {
      setError(error.error || "Failed to fetch submissions.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch all courses and populate the dropdown
  const fetchCourses = async () => {
    try {
      const response = await getCourses();
      const activeCourses = response.data.results.map((course) => ({
        label: course.title,
        value: course.id,
      }));
      setCourses([{ label: "All", value: null }, ...activeCourses]); // Include "All" option
    } catch (error) {
      setError("Failed to load courses.");
    }
  };

  useEffect(() => {
    fetchCourses(); // Fetch courses when component mounts
    fetchSubmissions(); // Fetch submissions without filters initially
  }, []);

  const handleCourseChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
    fetchSubmissions(selectedOption.value); // Fetch submissions with selected course_id
  };

  const options2 = [
    { label: "Ascending", value: "Ascending" },
    { label: "Descending", value: "Descending" },
  ];

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: mobileSidebar === 'disabled' ? "white" : "#131022",
      border: "none",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      paddingLeft: "5px",
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided) => ({
      ...provided,
      backgroundColor: mobileSidebar === 'disabled' ? "#fff" : "#000",
      color: mobileSidebar === 'disabled' ? '#000' : '#fff',
      fontSize: "14px",
      "&:hover": {
        backgroundColor: mobileSidebar === 'disabled' ? "#FFDEDA" : "#2b2838",
      },
    }),
    indicatorSeparator: (base) => ({ ...base, display: "none" }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
      display: "none"
    }),
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Quiz Attempts"} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            {userInfo?.role === "superadmin" ? (
              <AdminSidebar />
            ) : (
              <InstructorSidebar />
            )}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Assessments Submission</h3>
                  </div>
                  <div className="checkout-form">
                    <div className="announcement-filter-head">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-block customticket">
                            <label className="form-label">Courses</label>
                            <Select
                              className="form-select select country-select ticket-select"
                              options={courses}
                              value={selectedCourse}
                              placeholder="All"
                              onChange={handleCourseChange}
                              styles={style}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="input-block customticket">
                            <label className="form-label">Sort By</label>
                            <Select
                              className="form-select select country-select ticket-select"
                              options={options2}
                              defaultValue={options2[1]}
                              placeholder="Ascending"
                              styles={style}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-block customticket">
                            <label className="form-label">Created Date</label>
                            <DatePicker
                              className="form-control"
                              selected={selectedDate1}
                              onChange={handleDateChange1}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="table-responsive custom-table">
                      {loading ? (
                        <p>Loading...</p>
                      ) : error ? (
                        <p>{error}</p>
                      ) : (
                        <table className="table table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th>Quiz Info</th>
                              <th>Total Marks</th>
                              <th>Earned Marks</th>
                              <th>Result</th>
                              {/* <th>Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {submissions.map((submission) => (
                              <tr key={submission.id}>
                                <td>
                                  <div className="quiz-table">
                                    <p>{new Date(submission.submitted_at).toLocaleString()}</p>
                                    <p>{submission.assessment.title}</p>
                                    <p>
                                      <span>Student</span>: {submission.learner.first_name} {submission.learner.last_name}
                                    </p>
                                  </div>
                                </td>
                                <td>{submission.assessment.questions.length * 2}</td>
                                <td>{submission.grade} ({Math.round((submission.grade / (submission.assessment.questions.length * 2)) * 100)}%)</td>
                                <td>
                                  <span className={`resut-badge badge-light-${submission.is_passed ? "success" : "danger"}`}>
                                    {submission.is_passed ? "Pass" : "Fail"}
                                  </span>
                                </td>
                                {/* <td>
                                  <Link
                                    // to={`/student/student-quiz-details/${submission.id}`}
                                    to="#"
                                    className="btn btn-light-danger quiz-view"
                                  >
                                    Details
                                  </Link>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Instructor Quiz */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorQuizAttempts;
