import React, { useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import Footer from "../../footer";
import AdminSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { getUserProfile } from '../../../api';


const AdminProfile = () => {
    const [profile, setProfile] = useState({
        first_name: '',
       last_name: '',
        role: '',
        email: ''
    });

    useEffect(() => {

      const getProfile = async() => {
          try {
              const response = await getUserProfile();
              const { first_name,last_name, role, email } = response.data;
              setProfile({ first_name,last_name, role, email });
          } catch (error) {
              console.error('Error Getting Profile', error);
          }   
      }
      getProfile();
  }, []);


  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Profile"} />
      
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* sidebar */}
            <AdminSidebar />
            {/* /Sidebar */}
            {/* Instructor profile */}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details mb-0">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>My Profile</h3>
                  </div>
                  <div className="checkout-form personal-address">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="contact-info">
                          <h6>First Name</h6>
                          <p>{profile.first_name}</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="contact-info">
                          <h6>Last Name</h6>
                          <p>{profile.last_name}</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="contact-info">
                          <h6>Role</h6>
                          <p>{profile.role}</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="contact-info">
                          <h6>Email</h6>
                          <p>{profile.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Instructor profile */}
          </div>
        </div>
      </div>
      {/* /Page Content */}

      <Footer />
    </div>
  );
}

export default AdminProfile