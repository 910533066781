import React, { useEffect, useState, useContext } from "react";
import AdminSideBar from "../sidebar/";
import InstructorSideBar from "../../instructor/sidebar";
import { InstructorHeader } from "../../instructor/header";
import { listAllUsers, register, deactivateUser, updateUserProfile } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "learner", 
  });
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await listAllUsers();
      setUsers(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async () => {
    try {
      await register(
        newUser.first_name,
        newUser.last_name,
        newUser.email,
        newUser.password,
        null
      );
      toast.success("User added successfully");
      fetchUsers();
      setAddModalOpen(false); // Close modal
    } catch (error) {
      console.error(error);
      toast.error("Failed to add user");
    }
  };

  const handleDeactivateUser = async (userId) => {
    try {
      await deactivateUser(userId);
      toast.success("User deactivated successfully");
      fetchUsers();
    } catch (error) {
      console.error(error);
      toast.error("Failed to deactivate user");
    }
  };

  const handleUpdateUser = async () => {
    try {
      await updateUserProfile(selectedUser, localStorage.getItem("access_token"));
      toast.success("User updated successfully");
      fetchUsers();
      setUpdateModalOpen(false); // Close modal
    } catch (error) {
      console.error(error);
      toast.error("Failed to update user");
    }
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu="Manage Users" />
      <div className="page-content">
        <div className="container">
          <div className="row">
            {userInfo?.role === "superadmin" ? <AdminSideBar /> : <InstructorSideBar />}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>User Management</h3>
                    <p>Manage all system users</p>
                  </div>
                  <div className="checkout-form pb-0">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => setAddModalOpen(true)}
                    >
                      Add New User
                    </button>
                    <div className="tab-content mt-3">
                      {loading ? (
                        <p>Loading users...</p>
                      ) : (
                        <table className="table">
                          <thead style={{ backgroundColor: "#f0f0f0" }}>
                            <tr>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Role</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user) => (
                              <tr key={user.id}>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.role}</td>
                                <td>
                                  <button
                                    className="btn btn-warning btn-sm me-2"
                                    onClick={() => handleDeactivateUser(user.id)}
                                  >
                                    Deactivate
                                  </button>
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={() => {
                                      setSelectedUser(user);
                                      setUpdateModalOpen(true);
                                    }}
                                  >
                                    Update
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add User Modal */}
        {isAddModalOpen && (
          <div className="modal show d-block">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New User</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setAddModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newUser.first_name}
                        onChange={(e) =>
                          setNewUser({ ...newUser, first_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newUser.last_name}
                        onChange={(e) =>
                          setNewUser({ ...newUser, last_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={newUser.email}
                        onChange={(e) =>
                          setNewUser({ ...newUser, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        value={newUser.password}
                        onChange={(e) =>
                          setNewUser({ ...newUser, password: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Role</label>
                      <select
                        className="form-select"
                        value={newUser.role}
                        onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                      >
                        <option value="learner">Learner</option>
                        <option value="instructor">Instructor</option>
                        <option value="superadmin">Superadmin</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setAddModalOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddUser}
                  >
                    Save User
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Update User Modal */}
        {isUpdateModalOpen && selectedUser && (
          <div className="modal show d-block">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update User</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setUpdateModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={selectedUser.first_name}
                        onChange={(e) =>
                          setSelectedUser({ ...selectedUser, first_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={selectedUser.last_name}
                        onChange={(e) =>
                          setSelectedUser({ ...selectedUser, last_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={selectedUser.email}
                        onChange={(e) =>
                          setSelectedUser({ ...selectedUser, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Role</label>
                      <select
                        className="form-select"
                        value={selectedUser.role}
                        onChange={(e) =>
                          setSelectedUser({ ...selectedUser, role: e.target.value })
                        }
                      >
                        <option value="learner">Learner</option>
                        <option value="instructor">Instructor</option>
                        <option value="superadmin">Superadmin</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setUpdateModalOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpdateUser}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </div>
  );
};

export default ManageUsers;
