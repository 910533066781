import React from "react";
import ReactDOM from "react-dom/client";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

//Font Awesome
import "./assets/icons/fontawesome/css/fontawesome.min.css";
import "./assets/icons/fontawesome/css/all.min.css";

//Box icon
import "./assets/icons/boxicons/css/boxicons.min.css";
import "./index.css";

//CSS
import "./assets/css/style.css";

// Feather
import "./assets/icons/feather/css/iconfont.css"

import Approuter from "./approuter";
import { Provider } from "react-redux";
import store from "./components/common/redux/store.jsx";

// Import AuthProvider
import  AuthProvider  from "./context/AuthContext.js";

// Import Google OAuth Provider
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Provider store={store}>
    <GoogleOAuthProvider clientId="71009650769-i2tudfmoultt9smkq81bp21fimnhtd48.apps.googleusercontent.com"> 
      <AuthProvider> 
        <Approuter />
      </AuthProvider>
      </GoogleOAuthProvider>
    </Provider>
  </>
);
