import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SessionExpiredModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your session has expired. Please log in again.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => window.location.href = '/login'}>
          Go to Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionExpiredModal;
